import { Navigate, Route, Routes } from "react-router-dom";
import { LPPUrls } from "../../../LPPUrls";
import { ApplyPage } from "./Apply";
import { ApplyDetailPage } from "./ApplyDetail";
import { DetailPage } from "./Detail";
import { TablePage } from "./Table";

export const MDRoot = () => {
  return (
    <Routes>
      <Route path="/" element={<TablePage />} />
      <Route path={LPPUrls.Admin.MD.Detail.pathName} element={<DetailPage />} />
      <Route path={LPPUrls.Admin.MD.Apply.pathName} element={<ApplyPage />} />
      <Route
        path={LPPUrls.Admin.MD.ApplyDetail.pathName}
        element={<ApplyDetailPage />}
      />

      <Route path="*" element={<Navigate to={"/"} replace />} />
    </Routes>
  );
};

import { Link, useParams } from "react-router-dom";
import {
  Layout,
  Breadcrumb,
  Typography,
  theme,
  Descriptions,
  Result,
  Space,
  Image,
} from "antd";
import { LPPUrls } from "../../../LPPUrls";

import { useEffect, useState } from "react";
import axios, { AxiosError } from "axios";
import { LoadingSpin } from "../../../components/common/Loading";
import { LPPAXIOS } from "../../../framework/api/core.api";
import { HContent } from "../../../components/common/HContent";
import { timeConverter } from "../../../utils/timeConverter";
import useNotification from "antd/es/notification/useNotification";

const { Header } = Layout;
const { Title } = Typography;

export interface USERDETAILTYPE {
  id: number;
  nickName: string;
  email: string;
  type: string;
  profile: string;
  phone: string;
  createdAt: string;
  userType: string;
  address: string;
}

export const DetailPage = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<USERDETAILTYPE>({
    id: 0,
    nickName: "string",
    email: "string",
    type: "string",
    profile: "string",
    phone: "string",
    createdAt: "string",
    userType: "string",
    address: "string",
  });
  const [error, setError] = useState<any | AxiosError | null>(null);
  const [api, contextHolder] = useNotification();

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  useEffect(() => {
    setLoading(true);
    LPPAXIOS.get<USERDETAILTYPE>(`/admin/users/${id}`)
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          return setError(error);
        }
        return setError(error);
      })
      .finally(() => setLoading(false));
  }, [id]);

  if (error !== null) return <Result status={404} title={"404"} />;
  return (
    <>
      {contextHolder}
      <LoadingSpin loading={loading} />
      <Header
        style={{ background: colorBgContainer }}
        className="flex-col items-center justify-start py-1 my-1 mb-10 drop-shadow-sm"
      >
        <Title level={4}>차단 회원상세</Title>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={LPPUrls.Admin.BlockUser.Main.url()}>차단 회원 관리</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>회원 상세페이지</Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <HContent>
        <div className="flex flex-col gap-2">
          <Image
            preview={false}
            width={120}
            height={120}
            className="object-center"
            src={`${process.env.REACT_APP_IMG_ENDPOINT}/${data.profile}`}
          />

          <Descriptions bordered>
            <Descriptions.Item label="닉네임" span={3}>
              {data.nickName}
            </Descriptions.Item>
            <Descriptions.Item label="유저 타입" span={3}>
              {data.userType}
            </Descriptions.Item>
            <Descriptions.Item label="이메일" span={3}>
              {data.email}
            </Descriptions.Item>
            <Descriptions.Item label="연락처" span={2}>
              {data.phone}
            </Descriptions.Item>

            <Descriptions.Item label="가입경로">{data.type}</Descriptions.Item>
            <Descriptions.Item label="가입일자">
              {timeConverter(data.createdAt ?? "")}
            </Descriptions.Item>
          </Descriptions>
        </div>
        <Space className="justify-between w-full"></Space>
      </HContent>
    </>
  );
};

import { Button, Form, Input, message, Spin, Tabs } from "antd";
import { LockOutlined, UserOutlined, LoadingOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { LPPUrls } from "../LPPUrls";
import {
  LPPAXIOS,
  RequestPostAuth,
  ResponsePostAuth,
} from "../framework/api/core.api";
import { useState } from "react";
import Title from "antd/es/typography/Title";

const antIcon = <LoadingOutlined style={{ fontSize: 64 }} spin />;

export const AdminLoginPage = () => {
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();

  // Admin 로그인
  const handleLoginAdmin = async (loginValue: RequestPostAuth) => {
    try {
      setLoading(true);
      const res = await LPPAXIOS.post<ResponsePostAuth>("/admin/auth", {
        ...loginValue,
        role: "admin",
      });
      if (res.status === 200) {
        localStorage.setItem("lppuser", JSON.stringify(res.data));
        return navigate(LPPUrls.Admin.Root.pathName, { replace: true });
      }
      return messageApi.open({
        type: "error",
        content: "Fail to Login",
        duration: 2,
        style: {
          marginTop: "20vh",
        },
      });
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Fail to Login",
        duration: 2,
        style: {
          marginTop: "20vh",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  // MD 로그인
  const handleLoginMD = async (loginValue: RequestPostAuth) => {
    try {
      setLoading(true);
      const res = await LPPAXIOS.post<ResponsePostAuth>("/admin/auth", {
        ...loginValue,
        role: "md",
      });
      if (res.status === 200) {
        localStorage.setItem("lppuser", JSON.stringify(res.data));
        return navigate(LPPUrls.Admin.Root.pathName, { replace: true });
      }
      return messageApi.open({
        type: "error",
        content: "Fail to Login",
        duration: 2,
        style: {
          marginTop: "20vh",
        },
      });
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Fail to Login",
        duration: 2,
        style: {
          marginTop: "20vh",
        },
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    // Ver.02
    <div className="relative flex w-screen h-screen overflow-hidden">
      <div
        className={
          loading
            ? "w-screen h-screen bg-white/50 absolute z-10"
            : "hidden w-screen h-screen bg-white/50 absolute z-10"
        }
      >
        <Spin
          className="relative left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]"
          spinning={loading}
          indicator={antIcon}
          size="large"
        ></Spin>
      </div>

      <div className="flex justify-center w-full h-full">
        <div className="flex flex-col justify-center h-full px-10 ">
          {/* <LPPLOGO /> */}
          <Title level={3} className="">
            AZ Admin
          </Title>
          <Tabs
            defaultActiveKey="1"
            items={[
              {
                key: "1",
                label: "Admin Login",
                forceRender: true,
                children: (
                  <Form
                    layout="vertical"
                    name="normal_login"
                    className="w-[340px] font-bold"
                    initialValues={{ remember: true }}
                    onFinish={handleLoginAdmin}
                  >
                    <Form.Item
                      label={<span className="font-semibold ">ID</span>}
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: "이메일을 입력해주세요",
                        },
                      ]}
                    >
                      <Input prefix={<UserOutlined />} placeholder="email" />
                    </Form.Item>
                    <Form.Item
                      label={<span className="font-semibold ">Password</span>}
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "비밀번호를 입력해주세요.",
                        },
                      ]}
                    >
                      <Input
                        prefix={<LockOutlined />}
                        type="password"
                        placeholder="Password"
                      />
                    </Form.Item>
                    <Form.Item></Form.Item>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        block
                        className="font-bold"
                      >
                        Log in
                      </Button>
                    </Form.Item>
                  </Form>
                ),
              },
              {
                key: "2",
                label: "MD Login",
                children: (
                  <Form
                    layout="vertical"
                    name="md_login"
                    className="w-[340px] font-bold"
                    initialValues={{ remember: true }}
                    onFinish={handleLoginMD}
                  >
                    <Form.Item
                      label={<span className="font-semibold ">Email</span>}
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: "이메일을 입력해주세요.",
                        },
                      ]}
                    >
                      <Input
                        type="email"
                        prefix={<UserOutlined />}
                        placeholder="Username"
                      />
                    </Form.Item>
                    <Form.Item
                      label={<span className="font-semibold ">Password</span>}
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "비밀번호를 입력해주세요.",
                        },
                      ]}
                    >
                      <Input
                        prefix={<LockOutlined />}
                        type="password"
                        placeholder="Password"
                      />
                    </Form.Item>
                    <Form.Item>
                      {/* Custom Link or Routing */}
                      <Link
                        className="float-right"
                        to={LPPUrls.Auth.FindPassword.url}
                      >
                        MD 가입
                      </Link>
                    </Form.Item>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        block
                        className="font-bold"
                      >
                        Log in
                      </Button>
                    </Form.Item>
                  </Form>
                ),
              },
            ]}
          />
        </div>
      </div>
      {contextHolder}
    </div>
  );
};

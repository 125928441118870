import {
  Alert,
  Breadcrumb,
  Button,
  Card,
  Col,
  Layout,
  notification,
  Row,
  Space,
  Tag,
  theme,
  Typography,
} from "antd";

import { useState } from "react";

import { LPPAXIOS } from "../../../framework/api/core.api";
import axios from "axios";

import { HContent } from "../../../components/common/HContent";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { LPPUrls } from "../../../LPPUrls";
import Search from "antd/es/input/Search";

interface CollaboMember {
  id: number;
  role: string;
  nickName: string;
}
const { Header } = Layout;
const { Title } = Typography;

export const CreatePage = () => {
  const { role } = JSON.parse(localStorage.getItem("lppuser") ?? "");
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();

  const [creatorObj, setCreatorObj] = useState<null | CollaboMember>(null);
  const [sellerObj, setSellerObj] = useState<null | CollaboMember>(null);

  const handlePostChallenge = async () => {
    try {
      if (creatorObj === null || sellerObj === null) {
        throw Error();
      }
      setLoading(true);
      const post_data = {
        sellerId: sellerObj.id,
        creatorId: creatorObj.id,
      };

      const res = await LPPAXIOS.post(
        `/admin/match/collaboration/request`,
        post_data
      );
      if (res.status === 200) {
        api.success({
          message: `매칭이 신청되었습니다.`,
          description: "관리자의 승인을 대기하세요.",
        });
      }
    } catch (error) {
      console.log(error);
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 421) {
          api.error({
            message: `조회하신 셀러가 존재하지 않습니다.`,
          });
        }
        if (error.response?.status === 422) {
          api.error({
            message: `조회하신 크리에이터가 존재하지 않습니다.`,
          });
        }
        if (error.response?.status === 423) {
          api.error({
            message: `이미 신청된 매칭 콜라보가 존재합니다.`,
          });
        }
      }
    } finally {
      setLoading(false);
      setCreatorObj(null);
      setSellerObj(null);
    }
  };

  const handleSearchSeller = (value: string) => {
    LPPAXIOS.post<CollaboMember>(`/admin/users/nickName`, {
      nickName: value,
    })
      .then((resolve) => {
        switch (resolve.data.role) {
          case "seller":
            setSellerObj({ ...resolve.data, nickName: value });
            break;
          case "md":
            api.warning({
              message: "해당 계정은 MD 계정입니다.",
              description: "셀러의 앱 닉네임을 정확히 입력해주세요.",
            });
            break;
          case "creator":
            api.warning({
              message: "해당 계정은 크리에이터 계정입니다.",
              description: "셀러의 앱 닉네임을 정확히 입력해주세요.",
            });
            break;
          default:
            api.warning({
              message: "존재하지 않는 계정입니다.",
              description: "셀러의 앱 닉네임을 정확히 입력해주세요.",
            });
            break;
        }
      })
      .catch((error) => {
        api.error({
          message: "찾을 수 없는 아이디입니다.",
          description: "아이디를 올바르게 입력하세요.",
        });
      });
  };
  const handleSearchCreator = (value: string) => {
    LPPAXIOS.post<CollaboMember>(`/admin/users/nickName`, {
      nickName: value,
    })
      .then((resolve) => {
        switch (resolve.data.role) {
          case "creator":
            setCreatorObj({ ...resolve.data, nickName: value });
            break;
          case "md":
            api.warning({
              message: "해당 계정은 MD 계정입니다.",
              description: "크리에이터의 앱 닉네임을 정확히 입력해주세요.",
            });
            break;
          case "seller":
            api.warning({
              message: "해당 계정은 셀러 계정입니다.",
              description: "크리에이터의 앱 닉네임을 정확히 입력해주세요.",
            });
            break;
          default:
            api.warning({
              message: "존재하지 않는 계정입니다.",
              description: "크리에이터의 앱 닉네임을 정확히 입력해주세요.",
            });
            break;
        }
      })
      .catch((error) => {
        api.error({
          message: "찾을 수 없는 아이디입니다.",
          description: "아이디를 올바르게 입력하세요.",
        });
      });
  };
  if (role === "admin") {
    return <Navigate to={LPPUrls.Admin.Matching.Main.url()} replace />;
  }

  return (
    <>
      {contextHolder}

      <Header
        style={{ background: colorBgContainer }}
        className="flex-col items-center justify-start py-1 my-1 mb-10 drop-shadow-sm"
      >
        <Title level={4}>매칭 신청</Title>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={LPPUrls.Admin.Matching.Main.url()}>매칭 콜라보 조회</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>매칭 콜라보 신청</Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <HContent>
        <Row gutter={[8, 8]}>
          <Col sm={24} md={12}>
            <Card
              title={
                <Search
                  placeholder="셀러의 앱 닉네임을 검색하세요."
                  onSearch={handleSearchSeller}
                />
              }
            >
              {sellerObj === null ? (
                <Alert
                  type="warning"
                  message="매칭할 셀러의 계정이 필요합니다."
                />
              ) : (
                <Alert
                  type="success"
                  message={
                    <Space>
                      <Typography.Text>{sellerObj.nickName}</Typography.Text>
                      <Tag>{sellerObj.role}</Tag>
                    </Space>
                  }
                  action={
                    <Button onClick={() => setSellerObj(null)}>초기화</Button>
                  }
                />
              )}
            </Card>
          </Col>
          <Col sm={24} md={12}>
            <Card
              title={
                <Search
                  placeholder="크리에이터의 앱 닉네임을 검색하세요."
                  onSearch={handleSearchCreator}
                />
              }
            >
              {creatorObj === null ? (
                <Alert
                  type="warning"
                  message="매칭할 크리에이터의 계정이 필요합니다."
                />
              ) : (
                <Alert
                  type="success"
                  message={
                    <Space>
                      <Typography.Text>{creatorObj.nickName}</Typography.Text>
                      <Tag>{creatorObj.role}</Tag>
                    </Space>
                  }
                  action={
                    <Button onClick={() => setCreatorObj(null)}>초기화</Button>
                  }
                />
              )}
            </Card>
          </Col>
          <Col span={24}>
            <Button
              loading={loading}
              onClick={handlePostChallenge}
              type="primary"
              block
              disabled={creatorObj === null || sellerObj === null}
            >
              매칭 신청
            </Button>
          </Col>
        </Row>
      </HContent>
    </>
  );
};

import {
  Layout,
  Typography,
  theme,
  Table,
  Pagination,
  PaginationProps,
  notification,
  Button,
} from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { ColumnsType } from "antd/es/table";

import { LPPUrls } from "../../../LPPUrls";
import { LPPAXIOS } from "../../../framework/api/core.api";

import { LoadingSpin } from "../../../components/common/Loading";
import { timeConverter } from "../../../utils/timeConverter";

import axios from "axios";

// import Search from "antd/es/input/Search";
import { navFilter, urlFilter } from "../../../utils/paramsFilter";
import { MatchingColaboTable } from "./type";

export interface RESPONSETYPE {
  data: MatchingColaboTable[];
  total: number;
}

const { Title } = Typography;
const { Header, Content } = Layout;

export const TablePage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { role } = JSON.parse(localStorage.getItem("lppuser") ?? "");

  const params = new URLSearchParams(location.search);
  const search = params.get("search");
  const order = params.get("order");
  const sort = params.get("sort");
  const status = params.get("status");

  const [currentPage, setCurrentPage] = useState(Number(params.get("page")));
  const [total, setTotal] = useState(0);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<MatchingColaboTable[]>([]);
  const [api, contextHolder] = notification.useNotification();
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const AdminColumn: ColumnsType<MatchingColaboTable> = [
    {
      title: "셀러",
      dataIndex: "sellerNickName",
    },
    {
      title: "크리에이터",
      dataIndex: "creatorNickName",
    },

    {
      title: "생성 일자",
      dataIndex: "createdAt",
      render: (_, record) => timeConverter(record.createdAt),
    },
  ];

  const handleTableChange = (value: any, filter: any, sorter: any) => {
    let order =
      sorter.order === null ? null : sorter.order === "ascend" ? "ASC" : "DESC";
    let sort = sorter.field ?? null;
    let status =
      filter.isRecruitment === null
        ? null
        : filter.isRecruitment[0] === 1
        ? "모집중"
        : "모집완료";

    handleChangeSort(sort, order, status);
  };
  const handleChangeSort = (
    sort: any = null,
    order: any = null,
    status: any = null
  ) => {
    navigate(
      LPPUrls.Admin.Matching.Main.url(
        currentPage,
        navFilter({ search: search, sort: sort, order: order, status: status })
      )
    );
    setCurrentPage(currentPage);
  };

  const handleOnChangePage: PaginationProps["onChange"] = (page) => {
    navigate(
      LPPUrls.Admin.Matching.Main.url(
        page,
        navFilter({ search: search, sort: sort, order: order, status: status })
      )
    );
    setCurrentPage(page);
  };
  // const handleOnSearchFilter = (value: string) => {
  //   navigate(
  //     LPPUrls.Admin.Matching.Main.url(
  //       1,
  //       navFilter({ search: value, sort: sort, order: order, status: status })
  //     )
  //   );
  //   setCurrentPage(1);
  // };

  useEffect(() => {
    setLoading(true);
    LPPAXIOS.get<RESPONSETYPE>(
      urlFilter({
        url: `/admin/match/collaboration/list`,
        page: currentPage,
        perPage: 25,
        search: search,
        sort: sort,
        order: order,
        status: status,
      })
    )
      .then((res) => {
        setData(res.data.data);
        setTotal(res.data.total);
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          api.error({
            message: "매칭 목록을 불러오지 못했습니다.",
            description: error.response?.data.message,
          });
        }
      })
      .finally(() => setLoading(false));
  }, [currentPage, api, search, sort, currentPage, order, status]);

  return (
    <>
      <LoadingSpin loading={loading} />
      <Header
        style={{ background: colorBgContainer }}
        className="flex items-center justify-start py-1 my-2 mb-10 drop-shadow-sm"
      >
        <Title level={4}>매칭 콜라보 조회</Title>
      </Header>
      <Content className="m-6 bg-white">
        <Table
          rowClassName={() => "cursor-pointer"}
          pagination={false}
          rowKey={(render) => render.id}
          dataSource={data}
          columns={AdminColumn}
          title={() => (
            <div className="flex items-center justify-between p-2">
              {/* <Search
                className="w-60"
                placeholder="매칭을 검색하세요."
                onSearch={handleOnSearchFilter}
              /> */}
              {role === "md" && (
                <Button
                  type="primary"
                  disabled={role === "admin"}
                  onClick={() =>
                    navigate(LPPUrls.Admin.Matching.Create.pathName)
                  }
                >
                  매칭 콜라보 신청
                </Button>
              )}
            </div>
          )}
          footer={() => (
            <div className="flex justify-end">
              <Pagination
                current={currentPage}
                onChange={handleOnChangePage}
                pageSize={25}
                total={total}
                showSizeChanger={false}
              />
            </div>
          )}
          onRow={(record, _) => {
            return {
              onClick: () => {
                navigate(LPPUrls.Admin.Matching.Detail.url(record.id), {
                  state: { collabo: record },
                });
              }, // click row
            };
          }}
          onChange={(val: any, filter: any, sorter: any, extra: any) => {
            handleTableChange(val, filter, sorter);
          }}
        />
      </Content>
      {contextHolder}
    </>
  );
};

import { Routes, Route, Navigate } from "react-router-dom";
import { LPPUrls } from "../../../LPPUrls";
import { DetailPage } from "./Detail";
import { ReportListPage } from "./ReportList";
import { TablePage } from "./Table";
// import { UpdatePage } from "./Update";
// import { CreatePage } from "./Create";

export const ReviewRoot = () => {
  return (
    <Routes>
      <Route path="/" element={<TablePage />} />

      <Route
        path={LPPUrls.Admin.Review.Detail.pathName}
        element={<DetailPage />}
      />
      <Route
        path={LPPUrls.Admin.Review.ReportList.pathName}
        element={<ReportListPage />}
      />

      {/* <Route
        path={LPPUrls.Admin.Review.Create.pathName}
        element={<CreatePage />}
      /> */}

      {/* <Route
        path={LPPUrls.Admin.Review.Update.pathName}
        element={<UpdatePage />}
      /> */}

      <Route path="/*" element={<Navigate to={"/"} replace />} />
    </Routes>
  );
};

import { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Card,
  Divider,
  notification,
  Space,
  Typography,
} from "antd";
import { MailOutlined, PhoneOutlined } from "@ant-design/icons";
import { Layout } from "antd";
import { LPPUrls } from "../../../LPPUrls";
import { useNavigate } from "react-router-dom";
import { LPPAXIOS } from "../../../framework/api/core.api";
import { AdminTag } from "../../../components/common/AdminTag";
import { StaffTag } from "../../../components/common/StaffTag";
import axios from "axios";
import { GETAUTHTYPE } from "./Root";

enum LevelType {
  ADMIN = "admin",
  MD = "md",
}

interface InfoPageProps {
  data: GETAUTHTYPE | null;
}
export default function InfoPage(props: InfoPageProps) {
  const navigate = useNavigate();

  const { data } = props;
  const [api, contetxtHolder] = notification.useNotification();

  const handleLogout = async () => {
    try {
      const res = await LPPAXIOS.delete("/admin/auth");
      if (res.status === 204) {
        localStorage.removeItem("lppuser");
        return navigate(LPPUrls.Auth.Root.pathName, { replace: true });
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        api.error({
          message: `Notification ${error.code}`,
          description: `${error.message}`,
        });
      }
    }
  };

  return (
    <>
      {contetxtHolder}
      <Layout.Content className="p-2">
        <Card bordered className="border-solid border-1 drop-shadow-md">
          <div className="flex items-end justify-between">
            <Space>
              <Avatar size={32} />
              <Space direction="vertical">
                <span>
                  <Typography.Text strong>{data?.nickName}</Typography.Text>
                  {data?.role === LevelType.ADMIN && <AdminTag />}
                  {data?.role === LevelType.MD && <StaffTag />}
                </span>
              </Space>
            </Space>
          </div>
          <Divider />
          <div className="flex items-end justify-between">
            <MailOutlined />
            <Typography.Text strong>{data?.email}</Typography.Text>
          </div>
          <Divider />
          <div className="flex items-end justify-between">
            <PhoneOutlined />
            <Typography.Text strong>{data?.phone}</Typography.Text>
          </div>
          <Divider />
          <div className="flex items-end justify-end">
            <Button type="primary" htmlType="submit" onClick={handleLogout}>
              Log Out
            </Button>
          </div>
        </Card>
      </Layout.Content>
    </>
  );
}

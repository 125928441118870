import { Navigate, Route, Routes } from "react-router-dom";
import { LPPUrls } from "../../../LPPUrls";
import { ApplyPage } from "./Apply";
import { ApplyDetailPage } from "./ApplyDetail";
import { CreatePage } from "./Create";
import { DetailPage } from "./Detail";

import { TablePage } from "./Table";

export const MatchingRoot = () => {
  return (
    <Routes>
      <Route path="/" element={<TablePage />} />
      <Route
        path={LPPUrls.Admin.Matching.Detail.pathName}
        element={<DetailPage />}
      />
      <Route
        path={LPPUrls.Admin.Matching.Create.pathName}
        element={<CreatePage />}
      />
      <Route
        path={LPPUrls.Admin.Matching.Apply.pathName}
        element={<ApplyPage />}
      />

      <Route
        path={LPPUrls.Admin.Matching.ApplyDetail.pathName}
        element={<ApplyDetailPage />}
      />

      <Route path="*" element={<Navigate to={"/"} replace />} />
    </Routes>
  );
};

import {
  Layout,
  Typography,
  theme,
  Table,
  Input,
  notification,
  Pagination,
  PaginationProps,
  Space,
  Tag,
} from "antd";
import { ColumnsType } from "antd/es/table";
import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GoogleTag } from "../../../components/common/GoogleTag";
import { AppleTag } from "../../../components/common/AppleTag";
import { LoadingSpin } from "../../../components/common/Loading";
import { LPPAXIOS } from "../../../framework/api/core.api";
import { LPPUrls } from "../../../LPPUrls";
import { timeConverter } from "../../../utils/timeConverter";

import { preventSpaceBar } from "../../../utils/inputOnlyNumber";
import {
  paramsFilter,
  filterNonEmptyProperties,
} from "../../../utils/paramsFilter";
import { TableResponseType, TableUser } from "./type";

const { Header, Content } = Layout;
const { Title } = Typography;

export enum LoginType {
  GOOGLE = "google",
  FACEBOOK = "facebook",
  APPLE = "apple",
  NAVER = "naver",
  KAKAO = "kakao",
}

interface USERTYPE {
  id: number;
  nickName: string;
  type: string;
  createdAt: string;
  phone: string;
  email: string;
  userType: string;
}
//회원이름(닉네임), 이메일, 가입경로(구글, 페이스북), 탄소감축량, 가입일, 포인트
interface GETUSERLISTTYPE {
  data: USERTYPE[];
  total: number;
}

export const TablePage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const search = params.get("search");
  const order = params.get("order");
  const sort = params.get("sort");
  const type = params.get("type");
  const userType = params.get("userType");
  const status = params.get("status");
  const role = params.get("role");

  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(Number(params.get("page")));
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<TableUser[]>([]);
  const [api, contextHolder] = notification.useNotification();

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const userListColumn: ColumnsType<TableUser> = [
    {
      title: "닉네임",
      dataIndex: "nickName",
      sorter: true,
      defaultSortOrder:
        sort === "nickName" ? (order === "ASC" ? "ascend" : "descend") : null,
    },
    {
      title: "유저 타입",
      dataIndex: "userType",
      key: "role",
      render: (_, record) => {
        if (record.userType === "seller") {
          return <Tag color="red">셀러</Tag>;
        }
        if (record.userType === "creator") {
          return <Tag color="yellow">크리에이터</Tag>;
        }
        if (record.userType === "reviewer") {
          return <Tag color="green">리뷰어</Tag>;
        }
        if (record.userType === "md") {
          return <Tag color="orange">MD</Tag>;
        }
      },
      filterMultiple: false,
      filters: [
        {
          text: <Tag color="red">셀러</Tag>,
          value: "seller",
        },
        {
          text: <Tag color="yellow">크리에이터</Tag>,
          value: "creator",
        },
        {
          text: <Tag color="green">리뷰어</Tag>,
          value: "reviewer",
        },
        {
          text: <Tag color="orange">MD</Tag>,
          value: "md",
        },
      ],
      defaultFilteredValue: role === null ? [] : [role],
    },
    {
      title: "이메일",
      dataIndex: "email",
      ellipsis: true,
      sorter: true,
      defaultSortOrder:
        sort === "email" ? (order === "ASC" ? "ascend" : "descend") : null,
    },
    {
      title: "연락처",
      dataIndex: "phone",
      width: 150,
      ellipsis: true,
      sorter: true,
      defaultSortOrder:
        sort === "phone" ? (order === "ASC" ? "ascend" : "descend") : null,
    },

    {
      title: "가입경로",
      dataIndex: "type",
      filters: [
        {
          text: <GoogleTag />,
          value: "google",
        },
        {
          text: <AppleTag />,
          value: "apple",
        },
      ],
      filterMultiple: false,
      defaultFilteredValue: type === null ? [] : [type],

      render: (_, record) => {
        if (record.type === LoginType.GOOGLE) return <GoogleTag />;
        if (record.type === LoginType.APPLE) return <AppleTag />;

        return null;
      },
    },
    {
      title: "계정 상태",
      dataIndex: "status",
      render: (_, record) => {
        if (record.status === "general") {
          return <Tag color="green">인증 계정</Tag>;
        }
        if (record.status === "onRegist") {
          return <Tag color="orange">미인증 계정</Tag>;
        }
        if (record.status === "dormancy") {
          return <Tag color="pink">휴면 계정</Tag>;
        }
        if (record.status === "delete") {
          return <Tag color="red">탈퇴 계정</Tag>;
        }
      },
      filterMultiple: false,

      filters: [
        {
          text: <Tag color="green">인증 계정</Tag>,
          value: "general",
        },
        {
          text: <Tag color="orange">미인증 계정</Tag>,
          value: "onRegist",
        },
        {
          text: <Tag color="pink">휴면 계정</Tag>,
          value: "dormancy",
        },
        {
          text: <Tag color="red">탈퇴 계정</Tag>,
          value: "delete",
        },
      ],
      defaultFilteredValue: status === null ? [] : [status],
    },
    {
      title: "가입일자",
      dataIndex: "createdAt",
      width: 150,
      sorter: true,
      defaultSortOrder:
        sort === "createdAt" ? (order === "ASC" ? "ascend" : "descend") : null,
      render: (_, record) => timeConverter(record.createdAt).slice(0, 10),
    },
  ];

  const handleTableChange = (value: any, filter: any, sorter: any) => {
    let order =
      sorter.order === null ? null : sorter.order === "ascend" ? "ASC" : "DESC";
    let sort = sorter.field ?? null;

    let type =
      Array.isArray(filter["type"]) && filter["type"].length
        ? filter["type"][0]
        : null;

    const result = filterNonEmptyProperties(filter);

    handleChangeSort(sort, order, result);
  };

  const handleChangeSort = (
    sort: null | string,
    order: null | string,
    result: { [key: string]: string | null }
  ) => {
    // console.log(
    //   "필터 현재 상태 확인3::",
    //   paramsFilter({ search: search, sort: sort, order: order, ...result })
    // );
    navigate(
      LPPUrls.Admin.Users.Main.url(
        currentPage,
        paramsFilter({ search: search, sort: sort, order: order, ...result })
      )
    );
    setCurrentPage(currentPage);
  };

  const handleOnSearchFilter = (value: string) => {
    navigate(
      LPPUrls.Admin.Users.Main.url(
        1,
        paramsFilter({
          search: search,
          sort: sort,
          order: order,
          status: status,
          type: type,
          userType: userType,
          role: role,
        })
      )
    );
    setCurrentPage(1);
  };
  const handleOnChangePage: PaginationProps["onChange"] = (page) => {
    navigate(
      LPPUrls.Admin.Users.Main.url(
        page,
        paramsFilter({
          search: search,
          sort: sort,
          order: order,
          status,
          type,
          role,
        })
      )
    );
    setCurrentPage(page);
  };

  useEffect(() => {
    setLoading(true);
    LPPAXIOS.get<TableResponseType>(
      `/admin/users?` +
        paramsFilter({
          page: currentPage,
          perPage: 25,
          search: search,
          sort: sort,
          order: order,
          type: type,
          status: status,
          role: role,
        })
    )
      .then((res) => {
        setData(res.data.data);
        setTotal(res.data.total);
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          api.error({
            message: `Notification ${error.code}`,
            description: `${error.message}`,
          });
        }
      })
      .finally(() => setLoading(false));
  }, [api, currentPage, order, sort, type, search, status, role]);

  return (
    <>
      {contextHolder}
      <LoadingSpin loading={loading} />
      <Header
        style={{ background: colorBgContainer }}
        className="flex items-center justify-start py-1 my-2 mb-10 drop-shadow-sm"
      >
        <Title level={4}>회원관리</Title>
      </Header>
      <Content className="m-6 bg-white">
        <Table
          rowClassName={() => "cursor-pointer"}
          pagination={false}
          rowKey={(render) => render.id}
          dataSource={data}
          columns={userListColumn}
          onChange={(val: any, filter: any, sorter: any, extra: any) => {
            handleTableChange(val, filter, sorter);
          }}
          title={() => (
            <div className="flex items-center justify-between p-2">
              <Space>
                <Title level={4}>회원 리스트</Title>
              </Space>
              <Input.Search
                defaultValue={search ?? ""}
                style={{ width: 300 }}
                placeholder="회원의 이름 또는 이메일을 검색하세요"
                onSearch={handleOnSearchFilter}
                onInput={preventSpaceBar}
              />
            </div>
          )}
          footer={() => (
            <div className="flex justify-end">
              <Pagination
                current={currentPage}
                onChange={handleOnChangePage}
                pageSize={25}
                total={total}
                showSizeChanger={false}
              />
            </div>
          )}
          onRow={(record, _) => {
            return {
              onClick: () => {
                navigate(LPPUrls.Admin.Users.Detail.url(record.id));
              }, // click row
            };
          }}
        />
      </Content>
    </>
  );
};

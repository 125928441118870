import {
  Layout,
  Typography,
  theme,
  Table,
  Input,
  notification,
  Pagination,
  PaginationProps,
  Space,
} from "antd";

import { ColumnsType } from "antd/es/table";
import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LoadingSpin } from "../../../components/common/Loading";
import { LPPAXIOS } from "../../../framework/api/core.api";
import { LPPUrls } from "../../../LPPUrls";
import { timeConverter } from "../../../utils/timeConverter";
import { preventSpaceBar } from "../../../utils/inputOnlyNumber";
import { navFilter, urlFilter } from "../../../utils/paramsFilter";

const { Header, Content } = Layout;
const { Title } = Typography;

interface REPORTPOST {
  id: number;
  userId: number;
  targetId: number;
  type: string;
  detail: string;
  createdAt: string;
}

export interface GETUSERLISTTYPE {
  data: REPORTPOST[];
  total: number;
}

export const ReportListPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const search = params.get("search");
  const order = params.get("order");
  const sort = params.get("sort");
  const type = params.get("type");

  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(Number(params.get("page")));
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<REPORTPOST[]>([]);
  const [api, contextHolder] = notification.useNotification();

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const userListColumn: ColumnsType<REPORTPOST> = [
    // {
    //   title: "신고된 유저 닉네임",
    //   dataIndex: "nickName",
    //   sorter: true,
    //   defaultSortOrder:
    //     sort === "nickName" ? (order === "ASC" ? "ascend" : "descend") : null,
    // },
    {
      title: "유저 타입",
      dataIndex: "type",
    },
    {
      title: "신고 사유",
      dataIndex: "detail",
    },

    {
      title: "신고일",
      dataIndex: "createdAt",
      width: 150,
      sorter: true,
      defaultSortOrder:
        sort === "createdAt" ? (order === "ASC" ? "ascend" : "descend") : null,
      render: (_, record) => timeConverter(record.createdAt).slice(0, 10),
    },
  ];

  const handleTableChange = (value: any, filter: any, sorter: any) => {
    let order =
      sorter.order === null ? null : sorter.order === "ascend" ? "ASC" : "DESC";
    let sort = sorter.field ?? null;
    let type = filter["type"][0] ?? null;
    console.log(type);

    handleChangeSort(sort, order, type);
  };

  const handleChangeSort = (
    sort: null | string,
    order: null | string,
    type: any
  ) => {
    navigate(
      LPPUrls.Admin.Review.ReportList.url(
        currentPage,
        navFilter({ search: search, sort: sort, order: order, type: type })
      )
    );
    setCurrentPage(currentPage);
  };

  const handleOnSearchFilter = (value: string) => {
    navigate(
      LPPUrls.Admin.Review.ReportList.url(
        1,
        navFilter({ search: value, sort: sort, order: order, type: type })
      )
    );
    setCurrentPage(1);
  };
  const handleOnChangePage: PaginationProps["onChange"] = (page) => {
    navigate(
      LPPUrls.Admin.Review.ReportList.url(
        page,
        navFilter({ search: search, sort: sort, order: order, type: type })
      )
    );
    setCurrentPage(page);
  };

  useEffect(() => {
    setLoading(true);
    LPPAXIOS.get<GETUSERLISTTYPE>(
      urlFilter({
        url: `/admin/report`,
        page: currentPage,
        perPage: 25,
        search: search,
        sort: sort,
        order: order,
        type: type,
      }) + "&type=review"
    )
      .then((res) => {
        setData(res.data.data);
        setTotal(res.data.total);
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          api.error({
            message: `Notification ${error.code}`,
            description: `${error.message}`,
          });
        }
      })
      .finally(() => setLoading(false));
  }, [api, currentPage, order, sort, type, search]);

  return (
    <>
      {contextHolder}
      <LoadingSpin loading={loading} />
      <Header
        style={{ background: colorBgContainer }}
        className="flex items-center justify-start py-1 my-2 mb-10 drop-shadow-sm"
      >
        <Title level={4}>신고된 리뷰</Title>
      </Header>
      <Content className="m-6 bg-white">
        <Table
          rowClassName={() => "cursor-pointer"}
          pagination={false}
          rowKey={(render) => render.userId}
          dataSource={data}
          columns={userListColumn}
          onChange={(val: any, filter: any, sorter: any, extra: any) => {
            handleTableChange(val, filter, sorter);
          }}
          title={() => (
            <div className="flex items-center justify-between p-2">
              <Input.Search
                defaultValue={search ?? ""}
                style={{ width: 300 }}
                placeholder="회원의 이름을 검색하세요"
                onSearch={handleOnSearchFilter}
                onInput={preventSpaceBar}
              />
            </div>
          )}
          footer={() => (
            <div className="flex justify-end">
              <Pagination
                current={currentPage}
                onChange={handleOnChangePage}
                pageSize={25}
                total={total}
                showSizeChanger={false}
              />
            </div>
          )}
          onRow={(record, _) => {
            return {
              onClick: () => {
                navigate(LPPUrls.Admin.Review.Detail.url(record.targetId));
              }, // click row
            };
          }}
        />
      </Content>
    </>
  );
};

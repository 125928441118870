import {
  Button,
  Divider,
  Form,
  Input,
  Layout,
  notification,
  Select,
  theme,
  Typography,
  Upload,
  UploadFile,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useEffect, useLayoutEffect, useState } from "react";
import { LoadingSpin } from "../../../components/common/Loading";
import { LPPAXIOS } from "../../../framework/api/core.api";
import axios from "axios";

import { preventSpaceBar } from "../../../utils/inputOnlyNumber";
import { useNavigate, useParams } from "react-router-dom";
import { LPPUrls } from "../../../LPPUrls";
import TextArea from "antd/es/input/TextArea";
import { DetailNotation } from "./type";
import { handleUploadCompMultiFiles } from "../../../utils/uploadImgTos3";

const { Header, Content } = Layout;
const { Title } = Typography;

export const UpdatePage = () => {
  const { id } = useParams();

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [uploadFileList, setUploadFileList] = useState<UploadFile[]>([]);
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();

  const handlePostProduct = async (value: any) => {
    if (uploadFileList.length === 0) {
      api.error({
        message: "공지사항 썸네일 등록은 필수입니다.",
        description: "이미지를 등록해주세요",
      });
      return;
    }
    try {
      setLoading(true);

      const images =
        (await handleUploadCompMultiFiles(uploadFileList, "notice")) ?? [];

      const formData = {
        title: value.title.trim(),
        description: value.description,
        image: images,
        type: value.type,
      };

      const res = await LPPAXIOS.put(`/admin/notice/${id}`, formData);
      if (res.status === 200) {
        api.success({
          message: `공지사항 수정을 성공하였습니다.`,
          description: `product load success`,
        });
        setTimeout(() => {
          navigate(LPPUrls.Admin.Notation.Main.url());
        }, 500);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        api.error({
          message: `공지사항 수정을 실패하였습니다.`,
          description: `${error.response?.data.message}`,
        });
      }
    } finally {
      setLoading(false);
    }
  };
  const startEdit = (imageList: string[], stateFunc: Function) => {
    const imageFileList = imageList;
    const arr = [];
    for (let i = 0; i < imageFileList.length; i++) {
      const previewFile: UploadFile = {
        uid: `done file ${i}`,
        name: imageFileList[i],
        status: "done",
        url: `${process.env.REACT_APP_IMG_ENDPOINT}/${imageFileList[i]}`,
      };
      arr.push(previewFile);
    }
    stateFunc(arr);
  };
  useLayoutEffect(() => {
    setLoading(true);
    LPPAXIOS.get<DetailNotation>(`/admin/notice/${id}`)
      .then((resolve) => {
        form.setFieldsValue({ ...resolve.data });
        startEdit([resolve.data.image], setUploadFileList);
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      {contextHolder}
      <LoadingSpin loading={loading} />
      <Header
        style={{ background: colorBgContainer }}
        className="drop-shadow-sm"
      >
        <Title>공지사항 수정</Title>
      </Header>
      <Content className="m-6">
        <Form onFinish={handlePostProduct} style={{ width: 800 }} form={form}>
          <Form.Item label="공지사항 타입" name="type">
            <Select
              options={[
                { label: "일반 공지", value: "notice" },
                { label: "QnA 공지", value: "QnA" },
              ]}
            />
          </Form.Item>
          <Form.Item label="공지 썸네일">
            <Upload
              fileList={uploadFileList}
              accept="image/*"
              listType="picture-card"
              showUploadList={{ showPreviewIcon: false }}
              customRequest={({ onSuccess }) => {
                const res = "Ok";
                setTimeout(() => {
                  onSuccess!(res);
                }, 600);
              }}
              onChange={({ fileList }) => {
                setUploadFileList(fileList);
              }}
            >
              {uploadFileList.length >= 1 ? null : (
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>Upload</div>
                </div>
              )}
            </Upload>
          </Form.Item>
          <Form.Item
            label="공지제목"
            name="title"
            rules={[
              { required: true, message: "공지사항 제목 입력은 필수입니다." },
              {
                min: 8,
                message:
                  "공지사항 제목은 최소 8글자 이상 입력해주시기 바랍니다.",
              },
            ]}
          >
            <Input
              showCount
              maxLength={100}
              onChange={preventSpaceBar}
              onInput={preventSpaceBar}
            />
          </Form.Item>
          <Divider />
          <Typography.Title level={4}>공지사항 상세 설명</Typography.Title>
          <Form.Item
            name="description"
            rules={[
              { required: true, message: "공지사항 본문 입력은 필수입니다." },
              {
                min: 8,
                message: "공지사항 본문 최소 8글자 이상 입력해주시기 바랍니다.",
              },
            ]}
          >
            <TextArea />
          </Form.Item>
          <Form.Item>
            <div className="flex justify-center w-full">
              <Button type="primary" htmlType="submit">
                공지사항 수정
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Content>
    </>
  );
};

import {
  Layout,
  Typography,
  theme,
  Table,
  Pagination,
  PaginationProps,
  notification,
  Tag,
  Space,
} from "antd";
import { ColumnsType } from "antd/es/table";

import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LoadingSpin } from "../../../components/common/Loading";
import { LPPAXIOS } from "../../../framework/api/core.api";
import { LPPUrls } from "../../../LPPUrls";

import { timeConverter } from "../../../utils/timeConverter";
import { TableORDERTYPE } from "./type";

const { Header, Content } = Layout;
const { Title } = Typography;

// 구매자명, 거래일시, 가격, 배송비, 상태, 결제방법

interface GETORDERLISTTYPE {
  data: TableORDERTYPE[];
  total: number;
}
export const TablePage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const type = params.get("type");

  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(Number(params.get("page")));
  const [currentType, setCurrentType] = useState(type);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<TableORDERTYPE[]>([]);
  const [api, contextHolder] = notification.useNotification();

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const clubListColumn: ColumnsType<TableORDERTYPE> = [
    {
      title: "주문번호",
      dataIndex: "orderNumber",
    },
    {
      title: "구매자명",
      dataIndex: "nickName",
    },
    {
      title: "거래일시",
      dataIndex: "createdAt",
      render: (_, record) => timeConverter(record.createdAt),
    },
    {
      title: "결제금액",
      dataIndex: "totalPayment",
      render: (_, record) => record.totalPayment.toLocaleString("Kr"),
    },
    {
      title: "배송비",
      dataIndex: "deliveryFee",
      render: (_, record) => record.deliveryFee.toLocaleString("Kr"),
    },
    {
      title: "주문상태",
      dataIndex: "orderStatus",
      render: (_, record) => {
        let tagColor:
          | "success"
          | "warning"
          | "error"
          | "processing"
          | "default";
        switch (record.orderStatus) {
          case "결제취소":
            tagColor = "error";
            break;
          case "결제완료":
            tagColor = "success";
            break;
          default:
            tagColor = "default";
        }
        return <Tag color={tagColor}>{record.orderStatus}</Tag>;
      },
    },
    {
      title: "배송상태",
      dataIndex: "deliveryStatus",
      render: (_, record) => {
        if (record.orderStatus === "결제취소") {
          return null;
        } else {
          if (record.deliveryStatus === "배송준비") {
            return <Tag color="success">배송준비</Tag>;
          }
          if (record.deliveryStatus === "배송중") {
            return <Tag color="success">배송중</Tag>;
          }
          if (record.deliveryStatus === "주문완료") {
            return <Tag color="processing">주문완료</Tag>;
          }
          if (record.deliveryStatus === "배송완료") {
            return <Tag color="error">배송완료</Tag>;
          }
          if (record.deliveryStatus === "취소완료") {
            return <Tag color="error">취소완료</Tag>;
          }
        }
      },
    },
    {
      title: "결제방법",
      dataIndex: "paymentMethod",
    },
    // {
    //   title: (
    //     <a
    //       href={`${process.env.REACT_APP_ENDPOINT}${"/admin/order/download"}`}
    //       target="_blank"
    //     >
    //       <Button icon={<DownloadOutlined />} size="small" onClick={() => {}}>
    //         주문 리스트 다운로드
    //       </Button>
    //     </a>
    //   ),
    //   dataIndex: "operation",
    //   render: (_, record) => (
    //     <Link
    //       to={LPPUrls.Admin.Orders.Detail.url(record.order_number)}
    //       className="font-medium"
    //     >
    //       상세 페이지
    //     </Link>
    //   ),
    // },
  ];

  const handleOnChangePage: PaginationProps["onChange"] = (page) => {
    navigate(
      LPPUrls.Admin.Orders.Main.url(
        page,
        `${type === null ? "" : `&type=${type}`}`
      )
    );
    setCurrentPage(page);
  };

  const handleOnChangeType = (value: string | null) => {
    navigate(
      LPPUrls.Admin.Orders.Main.url(
        1,
        `${value === null ? "" : `&type=${value}`}`
      )
    );
    setCurrentPage(1);
    setCurrentType(value);
  };

  // const handleOnSearchFilter = (value: string) => {
  //   // navigate(
  //   //   LPPUrls.Admin.Record.Main.url(
  //   //     1,
  //   //     `${value}&${sort === null ? "sort=createdAt" : `sort=${sort}`}&${
  //   //       order === null ? "order=DESC" : `order=${order}`
  //   //     }`
  //   //   )
  //   // );
  //   // setCurrentPage(1);
  // };

  useEffect(() => {
    setLoading(true);
    LPPAXIOS.get<GETORDERLISTTYPE>(
      `/admin/payment?${
        type === null ? "" : `type=${type}`
      }&page=${currentPage}&perPage=25`
    )
      .then((res) => {
        setData(res.data.data);
        setTotal(res.data.total);
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          api.error({
            message: `Notification ${error.code}`,
            description: `${error.message}`,
          });
        }
      })
      .finally(() => setLoading(false));
  }, [api, type, currentPage]);

  return (
    <>
      {contextHolder}
      <LoadingSpin loading={loading} />
      <Header
        style={{ background: colorBgContainer }}
        className="flex items-center justify-start py-1 my-2 mb-10 drop-shadow-sm"
      >
        <Title level={4}>주문</Title>
      </Header>
      <Content className="m-6 bg-white">
        <Table
          rowClassName={() => "cursor-pointer"}
          pagination={false}
          columns={clubListColumn}
          dataSource={data}
          rowKey={(record) => record.id}
          title={() => (
            <div className="flex items-center justify-between p-2">
              <Title level={4}>주문 리스트</Title>
              <Space>
                {/* <Input.Search
                  style={{ width: 300 }}
                  placeholder="주문번호를 입력하세요."
                  onSearch={handleOnSearchFilter}
                  onInput={preventSpaceBar}
                /> */}
                {/* <Select
                  defaultValue={currentType}
                  style={{ width: 120 }}
                  onChange={handleOnChangeType}
                  options={[
                    { value: null, label: "전체" },
                    { value: "배송완료", label: "배송완료" },
                    { value: "배송중", label: "배송중" },
                    { value: "취소완료", label: "취소완료" },
                    { value: "주문완료", label: "주문완료" },
                  ]}
                /> */}
              </Space>
            </div>
          )}
          footer={() => (
            <div className="flex justify-end">
              <Pagination
                current={currentPage}
                onChange={handleOnChangePage}
                pageSize={25}
                total={total}
                showSizeChanger={false}
              />
            </div>
          )}
          onRow={(record, _) => {
            return {
              onClick: () => {
                navigate(LPPUrls.Admin.Orders.Detail.url(record.id));
              }, // click row
            };
          }}
        />
      </Content>
    </>
  );
};

import { Link, useParams } from "react-router-dom";
import {
  Layout,
  Breadcrumb,
  Typography,
  theme,
  Result,
  Button,
  Image,
  Divider,
  Space,
  Avatar,
  Alert,
} from "antd";

import { LPPUrls } from "../../../LPPUrls";
import { useEffect, useState } from "react";
import { LoadingSpin } from "../../../components/common/Loading";
import { LPPAXIOS } from "../../../framework/api/core.api";
import axios, { AxiosError } from "axios";

import { HContent } from "../../../components/common/HContent";
import useNotification from "antd/es/notification/useNotification";
import { DetailQnA, Reply } from "./type";
import { timeConverter } from "../../../utils/timeConverter";
import { urlFilter } from "../../../utils/paramsFilter";

const { Header } = Layout;
const { Title } = Typography;

export const DetailPage = () => {
  const [loading, setLoading] = useState(false);
  const [comments, setComments] = useState<Reply[]>([]);
  const [data, setData] = useState<DetailQnA>();
  const [error, setError] = useState<AxiosError | Error | null>(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [disable, setDisable] = useState(false);

  const [api, contextHolder] = useNotification();

  const { id } = useParams();

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const onClickNext = () => {
    setCurrentPage((prev) => prev + 1);
  };

  useEffect(() => {
    setLoading(true);
    LPPAXIOS.get<DetailQnA>(`/admin/question/${id}`)
      .then((res) => {
        setData(res.data);
      })
      .catch((error: Error | AxiosError) => {
        api.error({
          message: "상품 정보를 불러오지 못했습니다.",
        });
        if (axios.isAxiosError(error)) {
          return setError(error);
        }
        return setError(error);
      })
      .finally(() => setLoading(false));
  }, [id, api]);
  useEffect(() => {
    LPPAXIOS.get(
      urlFilter({
        url: `/admin/question/comment/${id}`,
        page: currentPage,
        perPage: 25,
      })
    )
      .then((resolve) => {
        console.log(resolve.data.data);
        let current = [...comments, ...resolve.data.data];
        if (current.length === resolve.data.total) {
          setDisable(true);
        }
        setComments((prev) => [...prev, ...resolve.data.data]);
        setTotal(resolve.data.total);
      })
      .catch((error) => {
        console.log(error);
        api.error({
          message: "댓글 조회 실패.",
        });
      });
  }, [currentPage, id]);

  if (error !== null)
    return (
      <Result
        title={error?.message}
        subTitle="Sorry, the page you visited does not exist."
        extra={<Button type="primary">Back Home</Button>}
      />
    );
  return (
    <>
      <LoadingSpin loading={loading} />
      <Header
        style={{ background: colorBgContainer }}
        className="flex-col items-center justify-start py-1 my-1 mb-10 drop-shadow-sm"
      >
        <Title level={4}>Q&A</Title>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={LPPUrls.Admin.QnA.Main.url()}>Q&A</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Q&A 상세 페이지</Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <HContent>
        <Typography>
          <span style={{ display: "flex", alignItems: "end" }}>
            <Avatar
              src={`${process.env.REACT_APP_IMG_ENDPOINT}/${data?.profile}`}
              size={48}
              shape="square"
            />
            <Typography.Text strong>{data?.nickName}</Typography.Text>
          </span>
          <Typography>
            <Typography.Text>조회수: {data?.views}</Typography.Text> |{" "}
            <Typography.Text>좋아요: {data?.totalLike}</Typography.Text>|{" "}
            <Typography.Text>
              작성일: {timeConverter(data?.createdAt ?? "")}
            </Typography.Text>
          </Typography>
        </Typography>
        {data?.isShow === 0 && (
          <Alert
            message="삭제된 Q&A"
            description="해당 게시글은 삭제된 Q&A입니다."
            type="error"
            showIcon
            closable
          />
        )}
        <Typography.Title level={4}>{data?.title}</Typography.Title>
        <Divider />
        <Typography>
          <Space direction="vertical">
            {data?.image.map((item, index) => (
              <Image
                key={`data-ddd-${index}`}
                preview={false}
                loading="lazy"
                width={300}
                src={`${process.env.REACT_APP_IMG_ENDPOINT}/${item}`}
              />
            ))}
          </Space>
          <Typography>
            {data?.description.split("\\n").map((item, index) => {
              return <p key={`descriptoin-${index}`}>{item}</p>;
            })}
          </Typography>
        </Typography>

        <Divider>댓글</Divider>

        <Typography>
          {comments.map((comment, index) => {
            const paddingLeft = `${comment.level * 40}px`;
            return (
              <div
                key={`${comment.id}-${index}`}
                className={`flex items-start justify-start gap-2 mb-2 w-full `}
                style={{ paddingLeft }}
              >
                <Avatar
                  src={`${process.env.REACT_APP_IMG_ENDPOINT}/${comment.profile}`}
                  className="min-w-[40px] h-[40px]"
                />
                <div className="flex flex-col items-start justify-start">
                  <div className="text-slate-400">
                    {comment.nickName === "" ? "알수없음" : comment.nickName}{" "}
                  </div>
                  <div className="text-md">
                    {comment.comment.split("\n").map((item, index) => {
                      return <p key={`${index}-comment`}>{item}</p>;
                    })}
                  </div>
                  <div className="text-slate-400">
                    {timeConverter(comment.createdAt)}
                  </div>
                </div>
              </div>
            );
          })}
        </Typography>
        <Button disabled={disable} onClick={onClickNext}>
          댓글 불러오기({comments.length}/{total})
        </Button>
      </HContent>
      {contextHolder}
    </>
  );
};

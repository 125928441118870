import { Link, useParams } from "react-router-dom";
import {
  Typography,
  Result,
  Image,
  Divider,
  notification,
  Avatar,
  Carousel,
  theme,
  Breadcrumb,
  Button,
  Alert,
  Tag,
} from "antd";
import { LPPUrls } from "../../../LPPUrls";
import { useEffect, useState } from "react";
import axios, { AxiosError } from "axios";
import { LPPAXIOS } from "../../../framework/api/core.api";
import { LoadingSpin } from "../../../components/common/Loading";
import { HContent } from "../../../components/common/HContent";

import { Header } from "antd/es/layout/layout";
import { DetailReview, Comment } from "./type";
import { urlFilter } from "../../../utils/paramsFilter";
import { timeConverter } from "../../../utils/timeConverter";

const { Title } = Typography;
export const DetailPage = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<DetailReview>();

  const [comments, setComments] = useState<Comment[]>([]);
  const [error, setError] = useState<any | AxiosError | null>(null);
  const [api, contextHolder] = notification.useNotification();
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [disable, setDisable] = useState(false);

  const onClickNext = () => {
    setCurrentPage((prev) => prev + 1);
  };
  useEffect(() => {
    LPPAXIOS.get<DetailReview>(`/admin/post/${id!}`)
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          setError(error);
        }
      })
      .finally(() => setLoading(false));
  }, [id]);

  useEffect(() => {
    LPPAXIOS.get(
      urlFilter({
        url: `/admin/post/${id!}/comment`,
        page: currentPage,
        perPage: 25,
      })
    )
      .then((resolve) => {
        console.log(resolve.data.data);
        let current = [...comments, ...resolve.data.data];
        if (current.length === resolve.data.total) {
          setDisable(true);
        }
        setComments((prev) => [...prev, ...resolve.data.data]);
        setTotal(resolve.data.total);
      })
      .catch((error) => {
        console.log(error);
        api.error({
          message: "댓글 조회 실패.",
        });
      });
  }, [currentPage, id]);

  if (error !== null) return <Result status={404} title={"404"} />;
  if (loading && data === undefined) return <LoadingSpin loading={loading} />;

  return (
    <>
      {contextHolder}

      <Header
        style={{ background: colorBgContainer }}
        // className="flex items-center justify-start py-1 my-2 mb-10 drop-shadow-sm"
        className="flex-col items-center justify-start py-1 my-1 mb-10 drop-shadow-sm"
      >
        <Title level={4}>리뷰 상세 조회</Title>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={LPPUrls.Admin.Review.Main.url()}>리뷰</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>상세 페이지</Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <HContent>
        {/* 리뷰 보여줄 곳 */}
        {data?.isShow === 0 && (
          <Alert
            message="삭제된 리뷰"
            description="해당 리뷰는 삭제된 리뷰입니다."
            type="error"
            showIcon
            closable
          />
        )}
        <Typography>
          <Avatar
            src={`${process.env.REACT_APP_IMG_ENDPOINT}/${data?.profile}`}
            size="small"
          />
          <Typography.Text>
            {data?.nickName} <Tag>{data?.role}</Tag>
          </Typography.Text>
        </Typography>

        <Typography>
          <Typography.Text>조회수: {data?.view}</Typography.Text>
        </Typography>
        <Divider
          orientation="center"
          orientationMargin={3}
          children="리뷰상품"
        />
        {data?.productData && (
          <Carousel autoplay className="bg-slate-500 w-[400px] pb-6">
            {data.productData.map((product, index) => (
              <div className="w-[400px] p-2  bg-slate-500 rounded-lg flex justify-start items-start gap-1">
                <Image
                  preview={false}
                  width={120}
                  height={120}
                  style={{ objectFit: "cover", borderRadius: 4 }}
                  src={`${process.env.REACT_APP_IMG_ENDPOINT}/${product.productRepresentativeImage}`}
                />

                <div>
                  <p className="w-[270px] font-semibold text-white truncate">
                    {product.productName}
                  </p>
                  <span className="text-white line-through ">
                    {product.originPrice}원
                  </span>
                  <p className="text-red-300">
                    {product.originPrice - product.discountPrice}원
                  </p>
                  {product.isDiscounted === 1 && (
                    <span className="text-lime-300">
                      {(product.discountPrice / product.originPrice) * 100}%
                      할인
                    </span>
                  )}
                </div>
              </div>
            ))}
          </Carousel>
        )}

        {/* 리뷰 댓글 보여줄 곳 */}
        <Divider orientation="center" orientationMargin={3} children="리뷰" />

        {data?.video.length === 0 ? (
          data.photos.map((item, index) => (
            <Image
              key={`data-ddd-${index}`}
              preview={false}
              loading="lazy"
              width={300}
              src={`${process.env.REACT_APP_IMG_ENDPOINT}/${item}`}
            />
          ))
        ) : (
          <video
            poster={`${process.env.REACT_APP_IMG_ENDPOINT}/${data?.thumbnail}`}
            src={`${process.env.REACT_APP_IMG_ENDPOINT}/${data?.video}`}
            width="300"
            autoPlay
            controls
            controlsList="nodownload"
          />
        )}
        <div>
          {data?.content.split("\\n").map((item, index) => {
            return <p key={`${index}-content`}>{item}</p>;
          })}
        </div>
        <Divider>댓글</Divider>

        <Typography>
          {comments.map((comment, index) => {
            const paddingLeft = `${comment.level * 40}px`;
            return (
              <div
                key={`${comment.id}-${index}`}
                className={`flex items-start justify-start gap-2 mb-2 w-full `}
                style={{ paddingLeft }}
              >
                <Avatar
                  src={`${process.env.REACT_APP_IMG_ENDPOINT}/${comment.profile}`}
                  size="large"
                />
                <div className="flex flex-col items-start justify-start">
                  <div className="text-slate-400">
                    {comment.nickName === "" ? "알수없음" : comment.nickName}{" "}
                  </div>
                  <div className="text-md">
                    {comment.comment}{" "}
                    {comment.isShow === 0 && (
                      <Tag color="red">삭제된 댓글입니다.</Tag>
                    )}
                  </div>
                  <div className="text-slate-400">
                    {timeConverter(comment.createdAt)}
                  </div>
                </div>
              </div>
            );
          })}
        </Typography>
        <Button disabled={disable} onClick={onClickNext}>
          댓글 불러오기({comments.length}/{total})
        </Button>
      </HContent>
    </>
  );
};

import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import {
  Layout,
  Breadcrumb,
  Typography,
  theme,
  notification,
  Descriptions,
  Divider,
  Button,
} from "antd";

import { LPPUrls } from "../../../LPPUrls";
import { useEffect, useState } from "react";
import { LPPAXIOS } from "../../../framework/api/core.api";
import axios from "axios";
import { LoadingSpin } from "../../../components/common/Loading";
import { timeConverter } from "../../../utils/timeConverter";

const { Header, Content } = Layout;
const { Title } = Typography;

interface RESPONSETYPE {
  id: number;
  userId: number;
  title: string;
  description: string;
  endDate: string;
  recruitPerson: number;
  photo: string;
  isRecruitment: number;
  view: number;
  createdAt: string;
  updatedAt: string;
  nickName: string;
  matchingUser: number;
}

export const DetailPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<RESPONSETYPE>();
  const [api, contextHolder] = notification.useNotification();

  const localData = localStorage.getItem("lppuser");

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  useEffect(() => {
    setLoading(true);
    LPPAXIOS.get<RESPONSETYPE>(`/admin/match/${id}`)
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          api.error({
            message: "셀러 정보 조회를 실패하였습니다.",
            description: error.response?.data.message,
          });
        }
      })
      .finally(() => setLoading(false));
  }, [id, api]);

  const handleDeleteRoom = () => {
    LPPAXIOS.delete(`/admin/match/${id}`)
      .then((resolve) => {
        api.success({ message: "매칭방 삭제" });
        setTimeout(() => {
          navigate(LPPUrls.Admin.MatchingRoom.Main.url(), { replace: true });
        }, 600);
      })
      .catch((error) => {
        api.error({
          message: "매칭방 삭제 실패",
          description: error.response.data.message ?? "",
        });
      });
  };

  if (localData === null) {
    return <Navigate to={LPPUrls.Auth.Root.pathName} replace />;
  }

  // const isAdmin = JSON.parse(localData).role === "admin" ? true : false;

  return (
    <>
      {contextHolder}
      <LoadingSpin loading={loading} />
      <Header
        style={{ background: colorBgContainer }}
        className="flex-col items-center justify-start py-1 my-1 mb-10 drop-shadow-sm"
      >
        <Title level={4}>매칭방 상세</Title>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={LPPUrls.Admin.MatchingRoom.Main.url()}>매칭방 조회</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>매칭방 상세조회</Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <Content className="p-2 m-6 bg-white">
        <Button danger onClick={() => handleDeleteRoom()}>
          매칭방 삭제
        </Button>

        <Descriptions bordered>
          <Descriptions.Item label="매칭 생성자">
            {data?.nickName}
          </Descriptions.Item>

          <Descriptions.Item label="생성일">
            {timeConverter(data?.createdAt ?? "")}
          </Descriptions.Item>
          <Descriptions.Item label="마감일">
            {timeConverter(data?.endDate ?? "")}
          </Descriptions.Item>
          <Descriptions.Item label="모집 인원">
            {data?.recruitPerson}
          </Descriptions.Item>

          <Descriptions.Item label="매칭 중인 인원">
            {data?.matchingUser}
          </Descriptions.Item>
        </Descriptions>
        <Divider />
        <Title>{data?.title}</Title>
        <Typography>
          {data?.description.split("\n").map((item, index) => {
            return <Typography key={index}>{item}</Typography>;
          })}
        </Typography>
      </Content>
    </>
  );
};

import { Button, Card, Col, Form, Input, Row } from "antd";
import { RuleObject } from "antd/lib/form";

import {
  MailOutlined,
  PhoneOutlined,
  LinkOutlined,
  UnlockOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { LPPUrls } from "../LPPUrls";

import { onPressOnlyNumber } from "../utils/inputOnlyNumber";
import TextArea from "antd/es/input/TextArea";
import Password from "antd/es/input/Password";
import { LPPAXIOS } from "../framework/api/core.api";
import useNotification from "antd/es/notification/useNotification";
import { useState } from "react";
import { LoadingSpin } from "../components/common/Loading";

interface FormValues {
  nickName: string;
  appNickName: string;
  phone: string;
  email: string;
  password: string;
  confirm_password: string;
  memo?: string;
}

export const FindPasswordPage = () => {
  const navigate = useNavigate();
  const [api, contextHolder] = useNotification();
  const [loading, setLoading] = useState(false);
  const onFinish = (value: FormValues) => {
    setLoading(false);
    const { confirm_password, ...info } = value;
    LPPAXIOS.post(`/admin/md`, info)
      .then((resolve) => {
        api.success({
          message: "MD가입 신청이 완료되었습니다.",
        });
        setTimeout(() => {
          navigate(LPPUrls.Auth.Login.Main.pathName);
        }, 600);
      })
      .catch((error) => {
        console.log(error.response.status);
        if (error.response.status === 409) {
          api.error({
            message: "해당계정으로 이미 가입신청이 되어있습니다.",
          });
        } else if (error.response.status === 419) {
          api.error({
            message: "이미 존재하는 MD 닉네임입니다",
          });
        } else if (error.response.status === 420) {
          api.error({
            message: "이미 존재하는 이메일입니다",
          });
        } else if (error.response.status === 421) {
          api.error({
            message: "입력하신 앱닉네임이 존재하지 않습니다.",
          });
        } else if (error.response.status === 422) {
          api.error({
            message: "해당 계정은 크리에이터 또는 셀러로 가입되어 있습니다.",
          });
        } else if (error.response.status === 423) {
          api.error({
            message: "해당계정으로 이미 가입신청이 되어있습니다.",
          });
        } else {
          api.error({
            message: error.response.data.message,
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const validatePasswords: RuleObject = {
    validator: async (_: RuleObject, value: string | number | undefined) => {
      if (!value || value === form.getFieldValue("password")) {
        return Promise.resolve();
      }
      return Promise.reject(new Error("비밀번호가 일치하지 않습니다."));
    },
  };

  const [form] = Form.useForm<FormValues>();
  return (
    <>
      <LoadingSpin loading={loading} />
      {contextHolder}
      <div className="relative flex justify-center w-screen h-screen overflow-scroll">
        <div className="px-1 py-2 w-fit h-fit">
          <Card title={"AZ MD 가입 신청서"}>
            <Form
              layout="vertical"
              name="normal_login"
              className="w-[900px]"
              onFinish={onFinish}
              form={form}
            >
              <Row gutter={8}>
                <Col span={12}>
                  <Form.Item
                    label={"MD 닉네임"}
                    name="nickName"
                    rules={[
                      {
                        required: true,
                        message:
                          "MD 페이지에서 사용할 닉네임 입력은 필수입니다.",
                      },
                    ]}
                    extra="MD 페이지에서 사용할 닉네임입니다."
                  >
                    <Input
                      className="rounded-none"
                      prefix={<UserOutlined />}
                      placeholder="MD페이지에서 사용할 닉네임"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={"AZ APP 닉네임"}
                    name="appNickName"
                    rules={[
                      {
                        required: true,
                        message:
                          "앱에서 사용하는 본인의 닉네임 입력은 필수입니다.",
                      },
                    ]}
                    extra="현재 앱에서 사용중인 닉네임을 정확히 입력하세요."
                  >
                    <Input
                      className="rounded-none"
                      prefix={<UserOutlined />}
                      placeholder="앱에서 사용하는 본인의 닉네임"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item
                label={"이메일"}
                name="email"
                extra="해당 이메일이 MD 페이지 로그인 아이디입니다."
                rules={[{ required: true, message: "이메일을 입력하세요." }]}
              >
                <Input
                  type="email"
                  autoComplete="off"
                  className="rounded-none"
                  prefix={<MailOutlined />}
                  placeholder="az.hellow@aizi.com"
                />
              </Form.Item>
              <Row gutter={8}>
                <Col span={12}>
                  <Form.Item
                    label={"비밀번호"}
                    name="password"
                    extra="특수문자를 포함한 알파벳과 숫자를 조합한 8자 이상"
                    rules={[
                      {
                        required: true,
                        message: "비밀번호 입력은 필수입니다.",
                      },
                      {
                        pattern: new RegExp(
                          "^(?=.*[a-z])(?=.*[0-9])(?=.*[^A-Za-z0-9\\s]).{8,30}$"
                        ),
                        message:
                          "비밀번호는 길이 8자 이상이며, 소문자, 숫자, 특수문자를 각각 최소 하나 이상 포함해야 합니다. 대문자와 공백은 사용할 수 없습니다.",
                      },
                    ]}
                  >
                    <Password
                      autoComplete="new-password"
                      className="rounded-none"
                      prefix={<UnlockOutlined />}
                      placeholder="비밀번호를 입력해주세요"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="비밀번호 확인"
                    name="confirm_password"
                    rules={[
                      {
                        validator(rule, value, callback) {
                          if (form.getFieldValue("password") === value) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject();
                          }
                        },
                        message: "비밀번호가 일치하지 않습니다.",
                      },
                    ]}
                  >
                    <Password
                      name="confirm_password"
                      className="rounded-none"
                      prefix={<UnlockOutlined />}
                      placeholder="비밀번호를 입력해주세요 (8자리 이상)"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                label="운영중인 홈페이지"
                name="platform"
                rules={[
                  {
                    required: true,
                    message:
                      "현재 운영중인 쇼핑몰 또는 플랫폼 주소를 입력해주세요.",
                  },
                ]}
              >
                <Input
                  className="rounded-none"
                  prefix={<LinkOutlined />}
                  placeholder="플랫폼 주소 or 운영중인 쇼핑몰 주소"
                />
              </Form.Item>
              <Form.Item
                label={"연락처"}
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "연락처 정보 입력은 필수입니다.",
                  },
                  {
                    pattern: new RegExp(/^\d+$/),
                    message: "숫자만 입력 가능합니다.",
                  },
                ]}
              >
                <Input
                  type="tel"
                  className="rounded-none"
                  maxLength={11}
                  prefix={<PhoneOutlined />}
                  placeholder="01012345678 (숫자만 입력)"
                  onChange={onPressOnlyNumber}
                />
              </Form.Item>

              <Form.Item label="요청설명" name="memo">
                <TextArea
                  maxLength={500}
                  className="rounded-none"
                  placeholder="추가적으로 전달하실 사항이 있다면 작성해주세요."
                />
              </Form.Item>
              <Form.Item>
                <Link
                  className="float-right text-blue-400"
                  to={LPPUrls.Auth.Login.Main.url}
                >
                  로그인 페이지
                </Link>
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  className="rounded-none"
                  loading={loading}
                >
                  가입 신청
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </div>
      </div>
    </>
  );
};

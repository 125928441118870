import { Tag } from "antd";
import { CrownOutlined } from "@ant-design/icons";

export const StaffTag = () => {
  return (
    <Tag color="lime" className="m-1" icon={<CrownOutlined />}>
      MD
    </Tag>
  );
};

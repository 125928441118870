interface IProps {
  children: React.ReactNode;
}
export const HContent = (props: IProps) => {
  return (
    <div className="flex flex-col gap-2 p-2 m-6 bg-white rounded-lg">
      {props.children}
    </div>
  );
};

import {
  Layout,
  Typography,
  theme,
  Button,
  notification,
  Input,
  Form,
  Divider,
} from "antd";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { LPPAXIOS } from "../../../framework/api/core.api";
import { LoadingSpin } from "../../../components/common/Loading";
import axios from "axios";
import { preventSpaceBar } from "../../../utils/inputOnlyNumber";
import { LevelType, PostAmdinistrators } from "./type";
import { LPPUrls } from "../../../LPPUrls";

const { Title } = Typography;
const { Header, Content } = Layout;

export const CreatePage = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [api, contextHolder] = notification.useNotification();
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const onFinish = async (value: PostAmdinistrators) => {
    try {
      setLoading(true);
      value = { ...value, role: LevelType.ADMIN };

      const res = await LPPAXIOS.post<PostAmdinistrators>(
        "/admin/administrators",
        value
      );
      api.success({
        message: "관리자 생성을 성공하였습니다.",
      });
      setTimeout(() => {
        navigate(LPPUrls.Admin.adminAccount.Main.url(), { replace: true });
      }, 600);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        api.error({
          message: error?.response?.data.message,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <LoadingSpin loading={loading} />
      <Header
        style={{ background: colorBgContainer }}
        className="flex items-center justify-start py-1 my-2 mb-10 drop-shadow-sm"
      >
        <Title level={4}>Admin Account</Title>
      </Header>
      <Content className="p-3 m-6 bg-white">
        <Form
          name="basic"
          onFinish={onFinish}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
        >
          <Form.Item
            label="아이디"
            name="nickName"
            rules={[
              {
                required: true,
                message: "아이디를 입력하세요",
              },
              { min: 5 },
            ]}
          >
            <Input maxLength={20} showCount onInput={preventSpaceBar} />
          </Form.Item>
          <Form.Item
            label="연락처"
            name="phone"
            rules={[
              {
                required: true,
                message: "연락처번호를 입력하세요",
              },
            ]}
          >
            <Input maxLength={14} onInput={preventSpaceBar} />
          </Form.Item>
          <Form.Item label="이메일" name="email">
            <Input
              type="email"
              placeholder="사용자 이메일 (로그인에 사용되는 아이디)"
            />
          </Form.Item>

          <Form.Item
            label="비밀번호"
            name="password"
            rules={[
              {
                required: true,
                message: "비밀번호를 입력하세요",
                pattern: RegExp("^[0-9a-zA-Z!@#$%^&*()?+-_~=]{8,30}$"),
              },
              { min: 8 },
            ]}
          >
            <Input.Password
              maxLength={30}
              showCount
              onInput={preventSpaceBar}
            />
          </Form.Item>
          <Divider />
          <Form.Item>
            <div className="flex justify-end gap-2">
              <Button onClick={() => navigate(-1)}>취소</Button>
              <Button htmlType="submit" type="primary" loading={loading}>
                관리자 생성
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Content>
      {contextHolder}
    </>
  );
};

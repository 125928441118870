import { Link, useParams } from "react-router-dom";
import {
  Layout,
  Breadcrumb,
  Typography,
  theme,
  Descriptions,
  Result,
  Card,
  Space,
  Image,
  Divider,
  Tag,
  Skeleton,
} from "antd";
import { LPPUrls } from "../../../LPPUrls";
import { useEffect, useState } from "react";
import axios, { AxiosError } from "axios";
import { LPPAXIOS } from "../../../framework/api/core.api";
import { HContent } from "../../../components/common/HContent";
import { DetailORDERTYPE } from "./type";

import { timeConverter } from "../../../utils/timeConverter";

const { Header } = Layout;
const { Title } = Typography;

export const DetailPage = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);

  const [data, setData] = useState<DetailORDERTYPE>();
  const [error, setError] = useState<any | AxiosError | null>(null);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  useEffect(() => {
    LPPAXIOS.get<DetailORDERTYPE>(`/admin/payment/orderList?id=${id}`)
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          return setError(error);
        }
        return setError(error);
      })
      .finally(() => setLoading(false));
  }, [id]);

  if (error !== null && error.response.status === 404) {
    return <Result status={404} title={"404"} />;
  }
  if (error !== null && error.response.status === 500) {
    return <Result status={500} title={"500"} />;
  }

  return (
    <>
      <Header
        style={{ background: colorBgContainer }}
        className="flex-col items-center justify-start py-1 my-1 mb-10 drop-shadow-sm"
      >
        <Title level={4}>주문</Title>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={LPPUrls.Admin.Orders.Main.url()}>주문 조회</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>주문 상세 페이지</Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      {loading ? (
        <HContent>
          <Skeleton active={loading} />
        </HContent>
      ) : (
        <HContent>
          <Typography.Title level={4}>주문상세 내역</Typography.Title>
          <Descriptions column={1} bordered>
            <Descriptions.Item label="주문일자">
              {data?.createdAt ? timeConverter(data.createdAt) : ""}
            </Descriptions.Item>
            <Descriptions.Item label="주문번호">
              {data?.orderNumber}
            </Descriptions.Item>
            <Descriptions.Item label="결제방법">
              {data?.paymentMethod}
            </Descriptions.Item>
          </Descriptions>
          <Card
            title={
              <Space className="flex justify-between w-full">
                <span>상품정보</span>
                <span>{data?.deliveryStatus}</span>
              </Space>
            }
          >
            <>
              {data?.orderList
                ? data.orderList.map((orderItem, idx) => {
                    return (
                      <>
                        <Space
                          key={idx}
                          className="flex items-start justify-start w-full py-2"
                        >
                          <Image
                            preview={false}
                            width={100}
                            src={`${process.env.REACT_APP_IMG_ENDPOINT}/${orderItem.productRepresentativeImage}`}
                          />
                          <Space className="flex flex-col items-start justify-start w-full">
                            <Typography.Title level={4}>
                              {orderItem.productName}
                            </Typography.Title>
                            <Typography.Text>
                              <Typography.Text strong>
                                {(
                                  orderItem.originPrice +
                                  (orderItem.optionalProductInfo
                                    ?.optionalPrice || 0) -
                                  (orderItem.discountPrice || 0)
                                ).toLocaleString()}
                                원
                              </Typography.Text>{" "}
                              {orderItem.isDiscounted ? (
                                <Typography.Text type="secondary" delete>
                                  {(
                                    orderItem.originPrice || 0
                                  ).toLocaleString()}
                                  원
                                </Typography.Text>
                              ) : null}
                            </Typography.Text>
                            <Typography.Text type="secondary">
                              <Tag className="rounded-none">옵션</Tag>
                              색상 : {orderItem.optionalProductInfo.color} /
                              사이즈 : {orderItem.optionalProductInfo.size}
                            </Typography.Text>

                            <Typography.Text type="secondary">
                              주문수량 : {orderItem.quantity}개
                            </Typography.Text>
                          </Space>
                        </Space>
                        <Divider />
                      </>
                    );
                  })
                : null}
            </>
          </Card>
          <Descriptions title="구매자 정보" column={1} bordered>
            <Descriptions.Item label="주문자">
              {data?.senderName}
            </Descriptions.Item>
            <Descriptions.Item label="연락처">
              {data?.senderPhone}
            </Descriptions.Item>
          </Descriptions>
          <Descriptions title="배송지 정보" column={1} bordered>
            <Descriptions.Item label="수령인">
              {data?.recipientName}
            </Descriptions.Item>
            <Descriptions.Item label="연락처">
              {data?.recipientPhone}
            </Descriptions.Item>
            <Descriptions.Item label="배송지">
              {data?.recipientAddress}
            </Descriptions.Item>
            <Descriptions.Item label="배송메모">
              {data?.receiving_location} / {data?.way_to_enter}
            </Descriptions.Item>
          </Descriptions>

          <Title level={4}>주문 금액 상세</Title>
          <Card>
            <Space className="flex justify-between w-full">
              <Typography.Text strong>주문금액</Typography.Text>
              <Typography.Text strong>
                {(
                  (data?.totalPrice || 0) + (data?.deliveryFee || 0)
                ).toLocaleString()}
                원
              </Typography.Text>
            </Space>
            <Space className="flex justify-between w-full">
              <Typography.Text type="secondary">ㄴ상품금액</Typography.Text>
              <Typography.Text type="secondary">
                {(data?.totalPrice || 0).toLocaleString()}원
              </Typography.Text>
            </Space>
            <Space className="flex justify-between w-full">
              <Typography.Text type="secondary">ㄴ배송비</Typography.Text>
              <Typography.Text type="secondary">
                {(data?.deliveryFee || 0).toLocaleString()}원
              </Typography.Text>
            </Space>
            <Divider />
            <Space className="flex justify-between w-full">
              <Typography.Text strong>할인금액</Typography.Text>
              <Typography.Text strong>
                -{(data?.totalDiscount || 0).toLocaleString()}원
              </Typography.Text>
            </Space>
            <Space className="flex justify-between w-full">
              <Typography.Text type="secondary">상품 할인</Typography.Text>
              <Typography.Text type="secondary">
                -{(data?.totalDiscount || 0).toLocaleString()}원
              </Typography.Text>
            </Space>
            <Divider />
            <Space className="flex justify-between w-full">
              <Typography.Text>총 주문금액</Typography.Text>
              <Typography.Text strong>
                {(data?.totalPayment || 0).toLocaleString()}원
              </Typography.Text>
            </Space>
          </Card>
        </HContent>
      )}
    </>
  );
};

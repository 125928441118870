import {
  Layout,
  Typography,
  theme,
  Table,
  Pagination,
  PaginationProps,
  // Image,
  // Button,
} from "antd";
import { ColumnsType } from "antd/es/table";
// import { DownloadOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import { LoadingSpin } from "../../../components/common/Loading";
import { LPPAXIOS } from "../../../framework/api/core.api";
import { LPPUrls } from "../../../LPPUrls";
import useNotification from "antd/es/notification/useNotification";
import { HContent } from "../../../components/common/HContent";
import { timeConverter } from "../../../utils/timeConverter";
import { REPORT, REPORTLIST } from "./type";

const { Header } = Layout;
const { Title } = Typography;

export const TablePage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const [currentPage, setCurrentPage] = useState(Number(params.get("page")));
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  const [api, contextHolder] = useNotification();
  const [data, setData] = useState<REPORT[]>([]);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const clubListColumn: ColumnsType<REPORT> = [
    { title: "신고자", dataIndex: "nickName" },

    { title: "신고 내용", dataIndex: "content", ellipsis: true },
    {
      title: "신고 시각",
      dataIndex: "createdAt",
      render: (_, record) => timeConverter(record.createdAt).slice(0, 11),
    },
  ];

  useEffect(() => {
    setLoading(true);
    LPPAXIOS.get<REPORTLIST>(`/admin/report?page=${currentPage}&perPage=25`)
      .then((res) => {
        setData(res.data.data);
        setTotal(res.data.total);
      })
      .catch((error) =>
        api.error({
          message: "신고 목록을 불러오지 못했습니다.",
        })
      )
      .finally(() => setLoading(false));
  }, [currentPage, api]);

  const handleOnChangePage: PaginationProps["onChange"] = (page) => {
    navigate(LPPUrls.Admin.Report.Main.url(page));
    setCurrentPage(page);
  };

  return (
    <>
      <LoadingSpin loading={loading} />
      <Header
        style={{ background: colorBgContainer }}
        className="flex items-center justify-start py-1 my-2 mb-10 drop-shadow-sm"
      >
        <Title level={4}>신고</Title>
      </Header>
      <HContent>
        <Table
          rowClassName={() => "cursor-pointer"}
          pagination={false}
          rowKey={(render) => render.userId}
          dataSource={data}
          columns={clubListColumn}
          footer={() => (
            <div className="flex justify-end">
              <Pagination
                current={currentPage}
                onChange={handleOnChangePage}
                pageSize={25}
                total={total}
                showSizeChanger={false}
              />
            </div>
          )}
          onRow={(record, _) => {
            return {
              onClick: () => {
                navigate(LPPUrls.Admin.Report.Detail.url(record.userId));
              }, // click row
            };
          }}
        />
      </HContent>
      {contextHolder}
    </>
  );
};

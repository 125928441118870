import {
  Button,
  Divider,
  Form,
  Input,
  Layout,
  notification,
  Select,
  theme,
  Typography,
  Upload,
  UploadFile,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useState } from "react";
import { LoadingSpin } from "../../../components/common/Loading";
import { LPPAXIOS } from "../../../framework/api/core.api";
import axios from "axios";

import { preventSpaceBar } from "../../../utils/inputOnlyNumber";
import { HContent } from "../../../components/common/HContent";
import { useNavigate } from "react-router-dom";
import { LPPUrls } from "../../../LPPUrls";
import TextArea from "antd/es/input/TextArea";
import { handleUploadCompMultiFiles } from "../../../utils/uploadImgTos3";

const { Header } = Layout;
const { Title } = Typography;

export const CreatePage = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [uploadFileList, setUploadFileList] = useState<UploadFile[]>([]);
  const [api, contextHolder] = notification.useNotification();

  const handlePostProduct = async (value: any) => {
    if (uploadFileList.length === 0) {
      api.error({
        message: "공지사항 썸네일 등록은 필수입니다.",
        description: "1개 이상의 이미지를 등록해주세요",
      });
      return;
    }
    try {
      setLoading(true);
      const images =
        (await handleUploadCompMultiFiles(uploadFileList, "notice")) ?? [];

      const formData = {
        image: [images[0]],
        title: value.title.trim(),
        description: value.description,
        type: value.type,
      };

      console.log(formData);

      const res = await LPPAXIOS.post(`/admin/notice`, formData);
      if (res.status === 200) {
        api.success({
          message: `공지사항 등록을 성공하였습니다. 공지사항 목록 페이지로 이동하세요`,
        });
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        api.error({
          message: `공지사항 등록을 실패하였습니다.`,
          description: `${error.message}`,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {contextHolder}
      <LoadingSpin loading={loading} />
      <Header
        style={{ background: colorBgContainer }}
        className="drop-shadow-sm"
      >
        <Title>공지사항 등록</Title>
      </Header>
      <HContent>
        <Form onFinish={handlePostProduct} style={{ width: 800 }}>
          <Form.Item label="공지사항 타입" name="type" initialValue={"notice"}>
            <Select
              options={[
                { label: "일반 공지", value: "notice" },
                { label: "QnA 공지", value: "QnA" },
              ]}
            />
          </Form.Item>
          <Form.Item label="공지 썸네일">
            <Upload
              fileList={uploadFileList}
              accept="image/*"
              listType="picture-card"
              showUploadList={{ showPreviewIcon: false }}
              customRequest={({ onSuccess }) => {
                const res = "Ok";
                setTimeout(() => {
                  onSuccess!(res);
                }, 600);
              }}
              onChange={({ fileList }) => {
                setUploadFileList(fileList);
              }}
            >
              {uploadFileList.length >= 1 ? null : (
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>Upload</div>
                </div>
              )}
            </Upload>
          </Form.Item>

          <Form.Item
            label="공지제목"
            name="title"
            rules={[
              { required: true, message: "공지사항 입력은 필수입니다." },
              { min: 10, message: "공지제목 최소 입력글자는 10글자입니다." },
            ]}
          >
            <Input showCount maxLength={100} onInput={preventSpaceBar} />
          </Form.Item>

          <Divider />
          <Typography.Title level={4}>공지 상세 설명</Typography.Title>
          <Form.Item
            name="description"
            rules={[
              { required: true, message: "공지사항 본문은 필수입니다." },
              { min: 10, message: "공지본문 최소 입력글자는 10글자입니다." },
            ]}
          >
            <TextArea />
          </Form.Item>
          <Form.Item>
            <div className="flex justify-center w-full">
              <Button type="primary" htmlType="submit">
                공지사항 등록
              </Button>
            </div>
          </Form.Item>
        </Form>
      </HContent>
    </>
  );
};

import {
  Layout,
  Typography,
  theme,
  Table,
  Pagination,
  PaginationProps,
  notification,
  Space,
  Tag,
} from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { ColumnsType } from "antd/es/table";

import { LPPUrls } from "../../../LPPUrls";
import { LPPAXIOS } from "../../../framework/api/core.api";

import { LoadingSpin } from "../../../components/common/Loading";
import { timeConverter } from "../../../utils/timeConverter";

import axios from "axios";

// import Search from "antd/es/input/Search";
import { navFilter, urlFilter } from "../../../utils/paramsFilter";
import { MatchingRoomTable } from "./type";

export interface RESPONSETYPE {
  data: MatchingRoomTable[];
  total: number;
}

const { Title } = Typography;
const { Header, Content } = Layout;

export const TablePage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { role } = JSON.parse(localStorage.getItem("lppuser") ?? "");

  const params = new URLSearchParams(location.search);
  const search = params.get("search");
  // const order = params.get("order");
  // const sort = params.get("sort");
  const status = params.get("status");

  const [currentPage, setCurrentPage] = useState(Number(params.get("page")));
  const [total, setTotal] = useState(0);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<MatchingRoomTable[]>([]);
  const [api, contextHolder] = notification.useNotification();
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const AdminColumn: ColumnsType<MatchingRoomTable> = [
    {
      title: "매칭제목",
      width: 200,
      dataIndex: "title",
    },
    {
      title: "모집인원",
      dataIndex: "recruitPerson",
    },
    {
      title: "매칭 중인 인원",
      dataIndex: "matchingUser",
    },
    {
      title: "작성자",
      dataIndex: "nickName",
      render: (_, record) => record.nickName,
    },
    {
      title: "매칭방 상태",
      dataIndex: "isShow",
      render: (_, record) => {
        let show = "default";
        let text = "없음.";
        switch (record.isShow) {
          case 1:
            show = "success";
            text = "운영중인 매칭방";
            break;
          case 0:
            show = "error";
            text = "삭제된 매칭방";
            break;
          default:
            show = "default";
            text = "없음.";
        }
        return <Tag color={show}>{text}</Tag>;
      },
    },
    {
      // 모집 완료 여부 1이면 모집중, 0이면 모집 완료
      title: "모집여부",
      dataIndex: "isRecruitment",
      render: (_, record) =>
        record.isRecruitment === 1 ? "모집중" : "모집완료",
      filters: [
        {
          text: "모집중",
          value: 1,
        },
        {
          text: "모집완료",
          value: 0,
        },
      ],
      filterMultiple: false,
      defaultFilteredValue:
        status === null ? [] : [status === "모집중" ? 1 : 0],
    },
    {
      title: "생성일",
      dataIndex: "createdAt",
      // sorter: true,
      // defaultSortOrder:
      //   sort === "createdAt" ? (order === "ASC" ? "ascend" : "descend") : null,
      render: (_, record) => timeConverter(record.createdAt),
    },
  ];

  const handleTableChange = (value: any, filter: any, sorter: any) => {
    let order =
      sorter.order === null ? null : sorter.order === "ascend" ? "ASC" : "DESC";
    let sort = sorter.field ?? null;
    let status =
      filter.isRecruitment === null
        ? null
        : filter.isRecruitment[0] === 1
        ? "모집중"
        : "모집완료";

    handleChangeSort(sort, order, status);
  };
  const handleChangeSort = (
    sort: any = null,
    order: any = null,
    status: any = null
  ) => {
    navigate(
      LPPUrls.Admin.MatchingRoom.Main.url(
        currentPage,
        navFilter({ search: search, status: status })
      )
    );
    setCurrentPage(currentPage);
  };

  const handleOnChangePage: PaginationProps["onChange"] = (page) => {
    navigate(
      LPPUrls.Admin.MatchingRoom.Main.url(
        page,
        navFilter({ search: search, status: status })
      )
    );
    setCurrentPage(page);
  };

  useEffect(() => {
    setLoading(true);
    LPPAXIOS.get<RESPONSETYPE>(
      urlFilter({
        url: `/admin/match`,
        page: currentPage,
        perPage: 25,
        search: search,

        status: status,
      })
    )
      .then((res) => {
        setData(res.data.data);
        setTotal(res.data.total);
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          api.error({
            message: "매칭 목록을 불러오지 못했습니다.",
            description: error.response?.data.message,
          });
        }
      })
      .finally(() => setLoading(false));
  }, [currentPage, api, search, status]);

  return (
    <>
      <LoadingSpin loading={loading} />
      <Header
        style={{ background: colorBgContainer }}
        className="flex items-center justify-start py-1 my-2 mb-10 drop-shadow-sm"
      >
        <Title level={4}>매칭방 조회</Title>
      </Header>
      <Content className="m-6 bg-white">
        <Table
          rowClassName={() => "cursor-pointer"}
          pagination={false}
          rowKey={(render) => render.id}
          dataSource={data}
          columns={AdminColumn}
          title={() => (
            <div className="flex items-center justify-between p-2">
              <Space>
                <Title level={4}>매칭방 조회</Title>
              </Space>
            </div>
          )}
          footer={() => (
            <div className="flex justify-end">
              <Pagination
                current={currentPage}
                onChange={handleOnChangePage}
                pageSize={25}
                total={total}
                showSizeChanger={false}
              />
            </div>
          )}
          onRow={(record, _) => {
            return {
              onClick: () => {
                navigate(LPPUrls.Admin.MatchingRoom.Detail.url(record.id));
              }, // click row
            };
          }}
          onChange={(val: any, filter: any, sorter: any, extra: any) => {
            handleTableChange(val, filter, sorter);
          }}
        />
      </Content>
      {contextHolder}
    </>
  );
};

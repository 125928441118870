export enum LevelType {
  ADMIN = "admin",
  STAFF = "staff",
}

export interface AdminDataType {
  id: number;
  name: string;
  role: LevelType;
  createdAt: string;
}

export interface GETDATATYPE {
  data: AdminDataType[];
  total: number;
}

export enum ACTIVETYPE {
  ACTIVE = "active",
  INACTIVE = "inactive",
}

export interface PostAmdinistrators {
  nickName: string;
  password: string;
  role: string;
  phone: string;
  email: string;
}

export interface Administrators {
  id: number;
  nickName: string;
  role: string;
  isDeleted: boolean;
  createdAt: string;
}

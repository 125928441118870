import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import {
  Layout,
  Breadcrumb,
  Typography,
  theme,
  notification,
  Descriptions,
  Space,
  Button,
  Skeleton,
  Divider,
} from "antd";

import { LPPUrls } from "../../../LPPUrls";
import { useEffect, useState } from "react";
import { LPPAXIOS } from "../../../framework/api/core.api";
import axios from "axios";
import { INCOMEAPPLYDETAIL } from "./type";

const { Header, Content } = Layout;
const { Title } = Typography;

export const ApplyDetailPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<INCOMEAPPLYDETAIL>();
  const [api, contextHolder] = notification.useNotification();

  const localData = localStorage.getItem("lppuser");

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const handleApprove = async () => {
    await LPPAXIOS.put(`/admin/settlement/request/${id}`)
      .then(() => {
        api.success({
          message: "지급완료",
        });
        setTimeout(() => {
          navigate(LPPUrls.Admin.Income.Apply.url(), { replace: true });
        }, 600);
      })
      .catch(function (error) {
        console.log(error);
        api.error({
          message: error.response?.data.message ?? "지급완료 실패",
        });
      });
  };
  useEffect(() => {
    LPPAXIOS.get<INCOMEAPPLYDETAIL>(`/admin/settlement/request/${id}`)
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          api.error({
            message: "정산 신청 정보 조회를 실패하였습니다.",
            description: error.response?.data.message,
          });
        }
      })
      .finally(() => setLoading(false));
  }, [id, api]);
  if (localData === null) {
    return <Navigate to={LPPUrls.Auth.Root.pathName} replace />;
  }

  return (
    <>
      {contextHolder}
      <Header
        style={{ background: colorBgContainer }}
        className="flex-col items-center justify-start py-1 my-1 mb-10 drop-shadow-sm"
      >
        <Title level={4}>정산 신청 상세 조회</Title>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={LPPUrls.Admin.Income.Apply.url()}>정산 신청 메인</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>정산 신청 상세 조회</Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <Content className="p-2 m-6 bg-white">
        {loading ? (
          <Skeleton active={loading} />
        ) : (
          <>
            <Descriptions column={2}>
              <Descriptions.Item label="앱닉네임">
                {data?.nickName}
              </Descriptions.Item>
              <Descriptions.Item label="예금주">{data?.name}</Descriptions.Item>
              <Descriptions.Item label="연락처">
                {data?.phone}
              </Descriptions.Item>
              <Descriptions.Item label="은행명">{data?.bank}</Descriptions.Item>
              <Descriptions.Item label="이메일">
                {data?.email}
              </Descriptions.Item>

              <Descriptions.Item label="계좌번호">
                {data?.credit}
              </Descriptions.Item>
              <Divider />
              <Descriptions.Item label="정산금액" span={2}>
                {(data?.paidAmount || 0).toLocaleString()} 원
              </Descriptions.Item>
            </Descriptions>
            <Descriptions column={1}></Descriptions>
            <Space className="flex justify-end w-full p-2">
              <Button
                loading={loading}
                disabled={data?.isPaid === 1}
                onClick={handleApprove}
                type="primary"
              >
                지급 완료
              </Button>
            </Space>
          </>
        )}
      </Content>
    </>
  );
};

import { Layout, Menu, Typography } from "antd";
import type { MenuProps } from "antd";
import { useEffect, useState } from "react";
import {
  DashboardOutlined,
  UserOutlined,
  AppstoreAddOutlined,
  ShoppingCartOutlined,
  ContactsOutlined,
  NotificationOutlined,
  QuestionCircleOutlined,
  PushpinOutlined,
} from "@ant-design/icons";
import { LPPUrls } from "../../LPPUrls";
import { Link, useLocation } from "react-router-dom";
import Title from "antd/es/typography/Title";

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key?: React.Key | null,
  icon?: React.ReactNode,
  children?: MenuItem[],
  theme?: "light" | "dark"
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    theme,
  } as MenuItem;
}

const { Sider } = Layout;

interface ILPPAdminCommonLayoutProps {
  children: React.ReactNode;
  role: "md" | "admin";
}

export const LPPAdminCommonLayout = (props: ILPPAdminCommonLayoutProps) => {
  const location = useLocation();
  const { children, role } = props;
  const [current, setCurrent] = useState("");
  const [collapsed, setCollapsed] = useState(false);

  const items: MenuProps["items"] = [
    getItem(
      <Link to={LPPUrls.Admin.MyPage.Main.url()}>마이페이지</Link>,
      LPPUrls.Admin.MyPage.key,
      <UserOutlined />
    ),
    role === "admin"
      ? getItem(
          <Link to={LPPUrls.Admin.adminAccount.Main.url()}>관리자</Link>,
          LPPUrls.Admin.adminAccount.key,
          <DashboardOutlined />
        )
      : null,
    role === "admin"
      ? getItem(
          <Link to={LPPUrls.Admin.Users.Main.url()}>회원</Link>,
          LPPUrls.Admin.Users.key,
          <ContactsOutlined />
        )
      : null,

    getItem("크리에이터", "CreatorManage", <AppstoreAddOutlined />, [
      getItem(
        <Link to={LPPUrls.Admin.Creator.Main.url()}>크리에이터 조회</Link>,
        LPPUrls.Admin.Creator.key
      ),
      role === "admin"
        ? getItem(
            <Link to={LPPUrls.Admin.Creator.Apply.url()}>
              크리에이터 신청 조회
            </Link>,
            LPPUrls.Admin.Creator.Apply.key
          )
        : null,
    ]),

    getItem("셀러", "SellerManage", <AppstoreAddOutlined />, [
      getItem(
        <Link to={LPPUrls.Admin.Seller.Main.url()}>셀러 조회</Link>,
        LPPUrls.Admin.Seller.key
      ),
      role === "admin"
        ? getItem(
            <Link to={LPPUrls.Admin.Seller.Apply.url()}>셀러 신청 조회</Link>,
            LPPUrls.Admin.Seller.Apply.key
          )
        : null,
    ]),

    role === "admin"
      ? getItem("MD", "MDManage", <AppstoreAddOutlined />, [
          getItem(
            <Link to={LPPUrls.Admin.MD.Main.url()}>MD 관리</Link>,
            LPPUrls.Admin.MD.key
          ),
          getItem(
            <Link to={LPPUrls.Admin.MD.Apply.url()}>MD 신청 조회</Link>,
            LPPUrls.Admin.MD.Apply.key
          ),
        ])
      : null,

    getItem("매칭", "MatchingManage", <AppstoreAddOutlined />, [
      role === "admin"
        ? getItem(
            <Link to={LPPUrls.Admin.MatchingRoom.Main.url()}>매칭방 조회</Link>,
            LPPUrls.Admin.MatchingRoom.key
          )
        : null,

      getItem(
        <Link to={LPPUrls.Admin.Matching.Main.url()}>매칭 콜라보 조회</Link>,
        LPPUrls.Admin.Matching.key
      ),

      // role === "md"
      //   ? getItem(
      //       <Link to={LPPUrls.Admin.CollaboOrder.Main.url()}>
      //         매칭 콜라보 리뷰 조회
      //       </Link>,
      //       LPPUrls.Admin.CollaboOrder.key
      //     )
      //   : null,
      role === "admin"
        ? getItem(
            <Link to={LPPUrls.Admin.Matching.Apply.url()}>
              매칭 콜라보 신청 조회
            </Link>,
            LPPUrls.Admin.Matching.Apply.key
          )
        : null,
    ]),

    role === "admin"
      ? getItem("상품", "ProductManage", <AppstoreAddOutlined />, [
          getItem(
            <Link to={LPPUrls.Admin.Products.Main.url()}>상품 조회</Link>,
            LPPUrls.Admin.Products.key
          ),
          getItem(
            <Link to={LPPUrls.Admin.Products.Category.url}>상품 카테고리</Link>,
            LPPUrls.Admin.Products.Category.key
          ),
        ])
      : null,

    getItem(
      <Link to={LPPUrls.Admin.Review.Main.url()}>리뷰</Link>,
      LPPUrls.Admin.Review.key,
      <NotificationOutlined />
    ),

    role === "admin"
      ? getItem(
          <Link to={LPPUrls.Admin.Orders.Main.url()}>주문</Link>,
          LPPUrls.Admin.Orders.key,
          <ShoppingCartOutlined />
        )
      : null,

    role === "admin"
      ? getItem("정산", "IncomeManage", <AppstoreAddOutlined />, [
          getItem(
            <Link to={LPPUrls.Admin.Income.Main.url()}>정산 완료 조회</Link>,
            LPPUrls.Admin.Income.key
          ),
          getItem(
            <Link to={LPPUrls.Admin.Income.Apply.url()}>정산 신청 조회</Link>,
            LPPUrls.Admin.Income.Apply.key
          ),
        ])
      : null,
    role === "admin"
      ? getItem(
          <Link to={LPPUrls.Admin.Notation.Main.url()}>공지사항</Link>,
          LPPUrls.Admin.Notation.key,
          <NotificationOutlined />
        )
      : null,

    getItem(
      <Link to={LPPUrls.Admin.QnA.Main.url()}>Q&A</Link>,
      LPPUrls.Admin.QnA.key,
      <QuestionCircleOutlined />
    ),

    role === "admin"
      ? getItem("신고&차단", "ReportManage", <AppstoreAddOutlined />, [
          getItem(
            <Link to={LPPUrls.Admin.Users.ReportList.url()}>
              신고 유저 조회
            </Link>,
            LPPUrls.Admin.Users.ReportList.key
          ),
          getItem(
            <Link to={LPPUrls.Admin.BlockUser.Main.url()}>차단 유저 조회</Link>,
            LPPUrls.Admin.BlockUser.key
          ),
          getItem(
            <Link to={LPPUrls.Admin.Review.ReportList.url()}>
              신고 리뷰 조회
            </Link>,
            LPPUrls.Admin.Review.ReportList.key
          ),
          getItem(
            <Link to={LPPUrls.Admin.MatchingRoom.ReportList.url()}>
              신고 매칭방 조회
            </Link>,
            LPPUrls.Admin.Matching.ReportList.key
          ),
        ])
      : null,
    role === "admin"
      ? getItem(
          <Link to={LPPUrls.Admin.Banner.Main.url()}>배너</Link>,
          LPPUrls.Admin.Banner.key,
          <PushpinOutlined />
        )
      : null,
    role === "admin"
      ? null
      : getItem(
          <Link to={LPPUrls.Admin.Settlement.Main.url()}>MD정산</Link>,
          LPPUrls.Admin.Settlement.key,
          <PushpinOutlined />
        ),
  ];

  useEffect(() => {
    setCurrent(location.pathname.split("/").slice(2, 4).join(""));
  }, [location.pathname]);

  return (
    <Layout className="w-screen h-screen">
      <Sider
        className="scrollbar-hide"
        style={{
          overflowY: "auto",
        }}
        // collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
      >
        <div className="p-0 m-0 text-center">
          <Title level={5} className="text-white">
            AZ {role.toUpperCase()}
            {process.env.REACT_APP_CHECK_ENV === "_dev_" && ".DEV"}
          </Title>
        </div>
        <Menu
          items={items}
          mode="inline"
          selectedKeys={[current]}
          theme={collapsed ? "dark" : "light"}
        />
      </Sider>
      <Layout className="h-screen overflow-auto bg-gray-50">{children}</Layout>
    </Layout>
  );
};

import { useEffect, useState } from "react";
import { notification, Tabs } from "antd";

import { LPPUrls } from "../../../LPPUrls";
import { useNavigate } from "react-router-dom";
import { LPPAXIOS } from "../../../framework/api/core.api";

import axios from "axios";
import { Content } from "antd/es/layout/layout";
import BankPage from "./Bank";
import InfoPage from "./Info";

export interface GETAUTHTYPE {
  id: number;
  userId: any;
  role: string;
  nickName: string;
  phone: string;
  email: string;
  password: string;
  salt: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: any;
  isDeleted: number;
  recommendedId: any;
  platform: any;
  memo: any;
  type: string;
}

export const MyPageRoot = () => {
  const [isMD, setIsMD] = useState(false);
  const [data, setData] = useState<GETAUTHTYPE | null>(null);

  useEffect(() => {
    LPPAXIOS.get<GETAUTHTYPE>(`/admin/auth`)
      .then((res) => {
        setData(res.data);
        if (res.data.role === "md") {
          setIsMD(true);
        }
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
        }
      });
  }, []);

  return (
    <>
      <Content className="p-2">
        <Tabs
          type="card"
          items={
            isMD
              ? [
                  {
                    label: `기본정보`,
                    key: "brand",
                    children: <InfoPage data={data} />,
                  },
                  {
                    label: `계좌정보`,
                    key: "bank",
                    children: <BankPage />,
                  },
                ]
              : [
                  {
                    label: `기본정보`,
                    key: "brand",
                    children: <InfoPage data={data} />,
                  },
                ]
          }
        />
      </Content>
    </>
  );
};

import { Button, Form, Input } from "antd";
import { Content } from "antd/es/layout/layout";
import useNotification from "antd/es/notification/useNotification";
import Title from "antd/es/typography/Title";
import { useEffect, useState } from "react";

import { LPPAXIOS } from "../../../framework/api/core.api";

export default function BankPage() {
  const [loading, setLoading] = useState(true);
  const [api, contextHolder] = useNotification();
  const [form] = Form.useForm();
  const [edit, setEdit] = useState(true);

  const onClickEdit = () => {
    setEdit((prev) => !prev);
  };

  const onFinish = (value: any) => {
    console.log(value);
    setLoading(true);
    LPPAXIOS.put(`/admin/settlement/credit`, value)
      .then((resolve) => {
        api.success({
          message: "계좌 정보 수정",
          description: "계좌 정보 수정이 완료되었습니다.",
        });
        onClickEdit();
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    LPPAXIOS.get("/admin/settlement/credit")
      .then((resolve) => {
        console.log(resolve.data);
        form.setFieldsValue({ ...resolve.data });
      })
      .catch((error) => {
        console.log(error);
        api.error({
          message: "정산계좌 정보를 불러오지 못햇습니다.",
          description: "정산 계좌를 등록해주세요.",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      {contextHolder}
      <Content className="">
        <Title>정산계좌</Title>
        <Form layout="vertical" onFinish={onFinish} form={form} disabled={edit}>
          <Form.Item label="예금주" name="name">
            <Input />
          </Form.Item>
          <Form.Item label="은행명" name="bank">
            <Input />
          </Form.Item>
          <Form.Item label="계좌번호" name="credit">
            <Input />
          </Form.Item>
          {!edit ? <Button htmlType="submit">정산계좌 정보 수정</Button> : null}
        </Form>
        <Button loading={loading} onClick={onClickEdit}>
          {edit ? "수정" : "취소"}
        </Button>
      </Content>
    </>
  );
}

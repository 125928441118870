import { Navigate, Route, Routes } from "react-router-dom";
import { LPPUrls } from "../../../LPPUrls";
import { CreatePage } from "./Create";

import { DetailPage } from "./Detail";
import { TablePage } from "./Table";

export const AdminAccountRoot = () => {
  return (
    <Routes>
      <Route path="/" element={<TablePage />} />
      <Route
        path={LPPUrls.Admin.adminAccount.Detail.pathName}
        element={<DetailPage />}
      />

      <Route
        path={LPPUrls.Admin.adminAccount.Create.pathName}
        element={<CreatePage />}
      />
      <Route path="*" element={<Navigate to={"/"} replace />} />
    </Routes>
  );
};

import {
  Layout,
  Typography,
  theme,
  Table,
  Pagination,
  PaginationProps,
  notification,
  Image,
} from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { ColumnsType } from "antd/es/table";

import { LPPUrls } from "../../../LPPUrls";
import { LPPAXIOS } from "../../../framework/api/core.api";

import { LoadingSpin } from "../../../components/common/Loading";
import { timeConverter } from "../../../utils/timeConverter";

import axios from "axios";

import Search from "antd/es/input/Search";
import { navFilter, urlFilter } from "../../../utils/paramsFilter";
import { TableMD } from "./type";

interface RESPONSETYPE {
  data: TableMD[];
  total: number;
}

const { Title } = Typography;
const { Header, Content } = Layout;

export const TablePage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const search = params.get("search");
  const order = params.get("order");
  const sort = params.get("sort");

  const [currentPage, setCurrentPage] = useState(Number(params.get("page")));
  const [total, setTotal] = useState(0);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<TableMD[]>([]);
  const [api, contextHolder] = notification.useNotification();
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const AdminColumn: ColumnsType<TableMD> = [
    {
      title: "닉네임",
      dataIndex: "nickName",
    },
    {
      title: "앱 닉네임",
      dataIndex: "appNickName",
    },
    {
      title: "연락처",
      dataIndex: "phone",
      render: (_, record) => record.phone,
    },
    {
      title: "이메일",
      dataIndex: "email",
    },
    {
      title: "생성일",
      dataIndex: "createdAt",
      sorter: true,
      defaultSortOrder:
        sort === "createdAt" ? (order === "ASC" ? "ascend" : "descend") : null,
      render: (_, record) => timeConverter(record.createdAt),
    },
  ];

  const handleTableChange = (value: any, filter: any, sorter: any) => {
    let order =
      sorter.order === null ? null : sorter.order === "ascend" ? "ASC" : "DESC";
    let sort = sorter.field ?? null;

    handleChangeSort(sort, order);
  };
  const handleChangeSort = (sort: any = null, order: any = null) => {
    navigate(
      LPPUrls.Admin.MD.Main.url(
        currentPage,
        navFilter({ search: search, sort: sort, order: order })
      )
    );
    setCurrentPage(currentPage);
  };

  const handleOnChangePage: PaginationProps["onChange"] = (page) => {
    navigate(
      LPPUrls.Admin.MD.Main.url(
        page,
        navFilter({ search: search, sort: sort, order: order })
      )
    );
    setCurrentPage(page);
  };
  const handleOnSearchFilter = (value: string) => {
    navigate(
      LPPUrls.Admin.MD.Main.url(
        1,
        navFilter({ search: value, sort: sort, order: order })
      )
    );
    setCurrentPage(1);
  };

  useEffect(() => {
    setLoading(true);
    LPPAXIOS.get<RESPONSETYPE>(
      urlFilter({
        url: `/admin/md`,
        page: currentPage,
        perPage: 25,
        search: search,
        sort: sort,
        order: order,
      })
    )
      .then((res) => {
        setData(res.data.data);
        setTotal(res.data.total);
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          api.error({
            message: "MD 목록을 불러오지 못했습니다.",
            description: error.response?.data.message,
          });
        }
      })
      .finally(() => setLoading(false));
  }, [currentPage, api, search, sort, order]);

  return (
    <>
      <LoadingSpin loading={loading} />
      <Header
        style={{ background: colorBgContainer }}
        className="flex items-center justify-start py-1 my-2 mb-10 drop-shadow-sm"
      >
        <Title level={4}>MD 관리</Title>
      </Header>
      <Content className="m-6 bg-white">
        <Table
          rowClassName={() => "cursor-pointer"}
          pagination={false}
          rowKey={(render) => render.id}
          dataSource={data}
          columns={AdminColumn}
          title={() => (
            <div className="flex items-center justify-between p-2">
              <Search
                className="w-60"
                placeholder="MD를 검색하세요."
                onSearch={handleOnSearchFilter}
              />
            </div>
          )}
          footer={() => (
            <div className="flex justify-end">
              <Pagination
                current={currentPage}
                onChange={handleOnChangePage}
                pageSize={25}
                total={total}
                showSizeChanger={false}
              />
            </div>
          )}
          onRow={(record, _) => {
            return {
              onClick: () => {
                navigate(LPPUrls.Admin.MD.Detail.url(record.userId));
              }, // click row
            };
          }}
          onChange={(val: any, filter: any, sorter: any, extra: any) => {
            handleTableChange(val, filter, sorter);
          }}
        />
      </Content>
      {contextHolder}
    </>
  );
};

import { useQuery } from "@tanstack/react-query";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { LPPAXIOS, ResponsePostAuth } from "../../framework/api/core.api";
import { LPPUrls } from "../../LPPUrls";
import { AdminAccountRoot } from "../../pages/AdminPages/adminAccount/Root";
import { BannerRoot } from "../../pages/AdminPages/Banner/Root";
import { BlockUserRoot } from "../../pages/AdminPages/BlockUser/Root";
import { CollaboOrderRoot } from "../../pages/AdminPages/CollaboOrder/Root";

import { CreatorRoot } from "../../pages/AdminPages/Creator/Root";
import { IncomeRoot } from "../../pages/AdminPages/Income/Root";
import { MatchingRoot } from "../../pages/AdminPages/Matching/Root";
import { MatchingRoomRoot } from "../../pages/AdminPages/MatchingRoom/Root";
import { MDRoot } from "../../pages/AdminPages/MD/Root";

import { MyPageRoot } from "../../pages/AdminPages/MyPage/Root";
import { NotationRoot } from "../../pages/AdminPages/Notation/Root";
import { OrdersRoot } from "../../pages/AdminPages/Orders/Root";
import { ProductsRoot } from "../../pages/AdminPages/Products/Root";
import { QnAsRoot } from "../../pages/AdminPages/QnA/Root";

import { ReportRoot } from "../../pages/AdminPages/Report/Root";
import { ReviewRoot } from "../../pages/AdminPages/Review/Root";
import { SellerRoot } from "../../pages/AdminPages/Seller/Root";
import { SettlementRoot } from "../../pages/AdminPages/Settlement/Root";

import { UsersRoot } from "../../pages/AdminPages/Users/Root";
import { LPPAdminCommonLayout } from "../Layout/LPPAdminCommonLayout";

interface LoginSessionType {
  // id: number;
  name: string;
  role: "admin" | "md";
  // createdAt: string;
  // updatedAt: string;
  // userId: number;
  // enabled: boolean;
  // type: "admin" | "md";
}

export const LPPAdminRoutes = () => {
  const navigate = useNavigate();
  useQuery({
    queryKey: ["useSession"],
    queryFn: async () => {
      try {
        const res = await LPPAXIOS.get<LoginSessionType>("/admin/auth");
        if (res.status === 200) {
          const { name, role } = res.data;
          localStorage.setItem("lppuser", JSON.stringify({ name, role }));
          return { name, role };
        }
        return null;
      } catch (error) {
        localStorage.removeItem("lppuser");
        return navigate(LPPUrls.Auth.Root.pathName, { replace: true });
      }
    },
    refetchOnWindowFocus: false,
    refetchInterval: 6600 * 1000,
  });

  let localData = localStorage.getItem("lppuser");

  if (localData === null) {
    return <Navigate to={LPPUrls.Auth.Root.pathName} replace />;
  }
  const data: ResponsePostAuth = JSON.parse(localData);

  return (
    <LPPAdminCommonLayout role={data.role}>
      <Routes>
        <Route
          path={LPPUrls.Admin.MyPage.Main.pathName}
          element={<MyPageRoot />}
        />
        {/* 관리자계정 페이지 라우터 */}
        {data.role === "admin" && (
          <Route
            path={LPPUrls.Admin.adminAccount.Main.pathName}
            element={<AdminAccountRoot />}
          />
        )}

        {/* MD 페이지 라우터 */}
        {data.role === "admin" && (
          <Route path={LPPUrls.Admin.MD.Main.pathName} element={<MDRoot />} />
        )}

        {/* 정산 페이지 라우터 */}
        {data.role === "admin" && (
          <Route
            path={LPPUrls.Admin.Income.Main.pathName}
            element={<IncomeRoot />}
          />
        )}

        {/* 배너 페이지 라우터 */}
        {data.role === "admin" && (
          <Route
            path={LPPUrls.Admin.Banner.Main.pathName}
            element={<BannerRoot />}
          />
        )}

        {/* 공지 사항 페이지 라우터 */}
        {data.role === "admin" && (
          <Route
            path={LPPUrls.Admin.Notation.Main.pathName}
            element={<NotationRoot />}
          />
        )}

        <Route
          path={LPPUrls.Admin.Users.Main.pathName}
          element={<UsersRoot />}
        />

        {/* 크리에이터 페이지 라우터 */}
        <Route
          path={LPPUrls.Admin.Creator.Main.pathName}
          element={<CreatorRoot />}
        />

        {/* 셀러 페이지 라우터 */}
        <Route
          path={LPPUrls.Admin.Seller.Main.pathName}
          element={<SellerRoot />}
        />

        {/* Matching 페이지 라우터 */}
        <Route
          path={LPPUrls.Admin.Matching.Main.pathName}
          element={<MatchingRoot />}
        />
        {/* MatchingRoom 페이지 라우터 */}
        {data.role === "admin" && (
          <Route
            path={LPPUrls.Admin.MatchingRoom.Main.pathName}
            element={<MatchingRoomRoot />}
          />
        )}

        {/* 상품 페이지 라우터 */}
        <Route
          path={LPPUrls.Admin.Products.Main.pathName}
          element={<ProductsRoot />}
        />

        {/* 리뷰 페이지 라우터 */}
        <Route
          path={LPPUrls.Admin.Review.Main.pathName}
          element={<ReviewRoot />}
        />

        {/* 공지 사항 페이지 라우터 */}
        {data.role === "admin" && (
          <Route
            path={LPPUrls.Admin.Notation.Main.pathName}
            element={<NotationRoot />}
          />
        )}

        {/* 주문 페이지 라우터 */}
        {data.role === "admin" && (
          <Route
            path={LPPUrls.Admin.Orders.Main.pathName}
            element={<OrdersRoot />}
          />
        )}

        {/* Q&A 페이지 라우터 */}
        <Route path={LPPUrls.Admin.QnA.Main.pathName} element={<QnAsRoot />} />

        {/* 신고 페이지 라우터 */}
        <Route
          path={LPPUrls.Admin.Report.Main.pathName}
          element={<ReportRoot />}
        />

        {/* 유저차단 페이지 라우터 */}
        <Route
          path={LPPUrls.Admin.BlockUser.Main.pathName}
          element={<BlockUserRoot />}
        />

        {/* MD 정산 페이지 라우터 */}
        {data.role === "md" && (
          <Route
            path={LPPUrls.Admin.Settlement.Main.pathName}
            element={<SettlementRoot />}
          />
        )}

        {/* MD 콜라보 리뷰 페이지 라우터 */}
        {data.role === "md" && (
          <Route
            path={LPPUrls.Admin.CollaboOrder.Main.pathName}
            element={<CollaboOrderRoot />}
          />
        )}
        <Route
          path={"*"}
          element={<Navigate to={LPPUrls.Admin.MyPage.Main.url()} />}
        />
      </Routes>
    </LPPAdminCommonLayout>
  );
};

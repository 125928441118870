import { Navigate, Route, Routes } from "react-router-dom";
import { ResponsePostAuth } from "../../../framework/api/core.api";
import { LPPUrls } from "../../../LPPUrls";
import { ApplyPage } from "./Apply";
import { ApplyDetailPage } from "./ApplyDetail";
import { DetailPage } from "./Detail";
import { TablePage } from "./Table";

export const CreatorRoot = () => {
  let localData = localStorage.getItem("lppuser");
  if (localData === null) {
    return <Navigate to={LPPUrls.Auth.Root.pathName} replace />;
  }
  const data: ResponsePostAuth = JSON.parse(localData);
  return (
    <Routes>
      <Route path="/" element={<TablePage />} />
      <Route
        path={LPPUrls.Admin.Creator.Detail.pathName}
        element={<DetailPage />}
      />
      {data.role === "admin" && (
        <Route
          path={LPPUrls.Admin.Creator.Apply.pathName}
          element={<ApplyPage />}
        />
      )}
      {data.role === "admin" && (
        <Route
          path={LPPUrls.Admin.Creator.ApplyDetail.pathName}
          element={<ApplyDetailPage />}
        />
      )}
      <Route path="*" element={<Navigate to={"/"} replace />} />
    </Routes>
  );
};

import { Link, useParams } from "react-router-dom";
import {
  Layout,
  Breadcrumb,
  Typography,
  theme,
  Divider,
  Image,
  Space,
  Result,
  Button,
  Carousel,
  Select,
  Tag,
  Descriptions,
} from "antd";
import parse from "html-react-parser";
import { LPPUrls } from "../../../LPPUrls";
import { useEffect, useState } from "react";
import { LoadingSpin } from "../../../components/common/Loading";
import { LPPAXIOS } from "../../../framework/api/core.api";
import axios, { AxiosError } from "axios";
import { HContent } from "../../../components/common/HContent";
import useNotification from "antd/es/notification/useNotification";
import { Product } from "./types";

const { Header } = Layout;
const { Title } = Typography;

export const DetailPage = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Product>();
  const [error, setError] = useState<AxiosError | Error | null>(null);

  const [api, contextHolder] = useNotification();

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  useEffect(() => {
    setLoading(true);
    LPPAXIOS.get<Product>(`/admin/product/${id}`)
      .then((res) => {
        setData(res.data);
      })
      .catch((error: Error | AxiosError) => {
        api.error({
          message: "상품 정보를 불러오지 못했습니다.",
        });
        if (axios.isAxiosError(error)) {
          return setError(error);
        }
        return setError(error);
      })
      .finally(() => setLoading(false));
  }, [id, api]);

  const handleUpdateRecommand = () => {
    setLoading(true);
    const status = data?.isRecommend === 1 ? 0 : 1;
    LPPAXIOS.put(`/admin/product/${id}`, {
      status: status,
    })
      .then((resolve) => {
        console.log(resolve.data);
        setData((prev) => {
          return { ...prev!, isRecommend: status };
        });
        api.success({
          message: "추천상품으로 등록되었습니다.",
        });
      })
      .catch((error) => {
        console.log(error.response?.data.message);
        api.error({
          message: "추천상품 등록을 실패하였습니다.",
          description: error.response?.data.message ?? "",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (error !== null)
    return (
      <Result
        title={error?.message}
        subTitle="Sorry, the page you visited does not exist."
        extra={<Button type="primary">Back Home</Button>}
      />
    );
  return (
    <>
      <LoadingSpin loading={loading} />
      <Header
        style={{ background: colorBgContainer }}
        className="flex-col items-center justify-start py-1 my-1 mb-10 drop-shadow-sm"
      >
        <Title level={4}>상품</Title>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={LPPUrls.Admin.Products.Main.url()}>상품</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>상세 페이지</Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <HContent>
        <Space className="flex items-start w-full">
          <Carousel autoplay className="w-[450px]">
            {data?.productRepresentativeImage.map((item, idx) => (
              <div
                className="flex justify-center bg-slate-200 "
                key={`caroucel-${idx}`}
              >
                <Image
                  preview={false}
                  height={300}
                  src={`${process.env.REACT_APP_IMG_ENDPOINT}/${item}`}
                  alt="product_thumbnail"
                  className="object-cover "
                />
              </div>
            ))}
          </Carousel>

          <Space className="flex flex-col items-start justify-start w-full">
            <Typography.Title level={3}>{data?.productName}</Typography.Title>
            <Typography.Text type="secondary">
              {data?.productDetailInfo}
            </Typography.Text>
            {data?.genderLabel === 1 ? (
              <Tag color="purple">남여공용</Tag>
            ) : data?.genderLabel === 2 ? (
              <Tag color="blue">남성전용</Tag>
            ) : (
              <Tag color="pink">여성전용</Tag>
            )}
            <div className="flex w-full flex-nowrap">
              {data?.hashtag
                ? data.hashtag.map((tag) => {
                    return <Tag>#{tag.tag}</Tag>;
                  })
                : null}
            </div>
            <Space className="flex items-end">
              {data?.isDiscounted ? (
                <Typography.Text strong className="text-lg text-red-600">
                  &#8361;
                  {(
                    (data?.originPrice ?? 0) - (data?.discountPrice ?? 0)
                  ).toLocaleString("Kr")}
                </Typography.Text>
              ) : (
                <Typography.Text>
                  &#8361;{data?.originPrice.toLocaleString("Kr")}
                </Typography.Text>
              )}

              {data?.isDiscounted && (
                <Typography.Text delete disabled>
                  &#8361;{data?.originPrice.toLocaleString("Kr")}
                </Typography.Text>
              )}
            </Space>
            <Typography.Text>
              배송비:
              {data?.isFreeDelivery
                ? "무료배송"
                : (data?.deliveryFee ?? 0).toLocaleString("Kr")}
            </Typography.Text>
            {data?.usedOptioned ? (
              <Select
                defaultValue={data.optionalProductInfo![0].key}
                style={{ width: 500 }}
                options={[
                  ...data.optionalProductInfo!.map((item) => {
                    return {
                      value: item.key,
                      label: `${item.color} / ${item.size} 재고수량: ${
                        item.amount
                      } 가격:${(
                        data.originPrice -
                        data.discountPrice +
                        item.optionalPrice
                      ).toLocaleString()}원`,
                      disabled: item.amount === 0 ? true : false,
                    };
                  }),
                ]}
              />
            ) : (
              <div>재고수량:{data?.productStockAmount}</div>
            )}
            <Button onClick={handleUpdateRecommand} loading={loading}>
              {data?.isRecommend ? "추천상품목록에서 제거" : "추천상품 등록"}
            </Button>
          </Space>
        </Space>

        <Divider />

        <Typography>
          <Typography.Title level={4}>상품 상세 정보</Typography.Title>
          <div className="flex flex-col items-center w-full">
            {data?.productAdditionalImages.map((item, index) => {
              return (
                <Image
                  preview={false}
                  className="w-[600px]"
                  key={`productAdditionalImages-index-${index}`}
                  src={`${process.env.REACT_APP_IMG_ENDPOINT}/${item}`}
                />
              );
            })}
          </div>
        </Typography>
        <Descriptions title="상품정보제공고시" bordered column={2}>
          <Descriptions.Item label="상품군">
            {data?.productGroup}
          </Descriptions.Item>
          <Descriptions.Item label="제품소재">
            {data?.productMaterial}
          </Descriptions.Item>
          <Descriptions.Item label="색상">
            {data?.productColor}
          </Descriptions.Item>
          <Descriptions.Item label="치수">
            {data?.productSize}
          </Descriptions.Item>
          <Descriptions.Item label="제조자">
            {data?.manufacturer}
          </Descriptions.Item>
          <Descriptions.Item label="제조국">
            {data?.countryOfManufacture}
          </Descriptions.Item>
          <Descriptions.Item label="세탁방법 및 취급 시 주의사항">
            {data?.washingMethodAndHandlingPrecautions}
          </Descriptions.Item>
          <Descriptions.Item label="제조연월">
            {data?.manufacturingYearAndMonth}
          </Descriptions.Item>
          <Descriptions.Item label="품질보증기준">
            {data?.qualityAssuranceStandards}
          </Descriptions.Item>
          <Descriptions.Item label="A/S 책임자와 연락처">
            {data?.managerAndPhoneNumber}
          </Descriptions.Item>
        </Descriptions>
        <Divider />

        <Descriptions title="배송" bordered column={2}>
          <Descriptions.Item label="배송비 무료여부">
            {data?.isFreeDelivery ? "무료" : "유료"}
          </Descriptions.Item>
          <Descriptions.Item label="배송비">
            {data?.deliveryFee}
          </Descriptions.Item>
          <Descriptions.Item label="발송예정일">
            {data?.estimatedShipDate}
          </Descriptions.Item>
        </Descriptions>
        <Divider />
        <Descriptions title="교환/반품" bordered column={2}>
          <Descriptions.Item label="반품/교환 택배사">
            {data?.returnExchangeCourier}
          </Descriptions.Item>
          <Descriptions.Item label="반품배송비(편도)">
            {data?.returnShippingFeeOneWay}
          </Descriptions.Item>
          <Descriptions.Item label="반품배송비(왕복)">
            {data?.returnShippingFeeRoundTrip}
          </Descriptions.Item>
          <Descriptions.Item label="반품교환지">
            {data?.returnExchangeLocation}
          </Descriptions.Item>
        </Descriptions>
        <Divider />
        <Descriptions title="A/S, 특이사항" bordered column={2}>
          <Descriptions.Item label="A/S 연락처">
            {data?.asPhoneNumber}
          </Descriptions.Item>
          <Descriptions.Item label="A/S 안내">
            {data?.asInformation}
          </Descriptions.Item>
          <Descriptions.Item label="판매자특이사항">
            {data?.sellerSpecifics}
          </Descriptions.Item>
        </Descriptions>
      </HContent>
      {contextHolder}
    </>
  );
};

import { Link, Navigate, useParams } from "react-router-dom";
import {
  Layout,
  Breadcrumb,
  Typography,
  theme,
  notification,
  Descriptions,
} from "antd";

import { LPPUrls } from "../../../LPPUrls";
import { useEffect, useState } from "react";
import { LPPAXIOS } from "../../../framework/api/core.api";
import axios from "axios";
import { LoadingSpin } from "../../../components/common/Loading";
import { timeConverter } from "../../../utils/timeConverter";
import { DetailMD } from "./type";

const { Header, Content } = Layout;
const { Title } = Typography;

export const DetailPage = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<DetailMD>();
  const [api, contextHolder] = notification.useNotification();
  const localData = localStorage.getItem("lppuser");

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  useEffect(() => {
    setLoading(true);
    LPPAXIOS.get<DetailMD>(`/admin/md/${id}`)
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          api.error({
            message: "MD 정보 조회를 실패하였습니다.",
            description: error.response?.data.message,
          });
        }
      })
      .finally(() => setLoading(false));
  }, [id, api]);

  if (localData === null) {
    return <Navigate to={LPPUrls.Auth.Root.pathName} replace />;
  }

  // const isAdmin = JSON.parse(localData).role === "admin" ? true : false;

  return (
    <>
      {contextHolder}
      <LoadingSpin loading={loading} />
      <Header
        style={{ background: colorBgContainer }}
        className="flex-col items-center justify-start py-1 my-1 mb-10 drop-shadow-sm"
      >
        <Title level={4}>MD Account</Title>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={LPPUrls.Admin.MD.Main.url()}>MD Account</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>MD 상세조회</Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <Content className="p-2 m-6 bg-white">
        <Descriptions bordered>
          <Descriptions.Item label="앱 닉네임">
            {data?.appNickName}
          </Descriptions.Item>
          <Descriptions.Item label="닉네임">{data?.nickName}</Descriptions.Item>
          <Descriptions.Item label="이메일">{data?.email}</Descriptions.Item>
          <Descriptions.Item label="연락처">{data?.phone}</Descriptions.Item>

          <Descriptions.Item label="생성일">
            {timeConverter(data?.createdAt ?? "")}
          </Descriptions.Item>
        </Descriptions>
      </Content>
    </>
  );
};

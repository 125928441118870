import {
  Layout,
  Typography,
  theme,
  Table,
  Pagination,
  PaginationProps,
  notification,
  Image,
  Tag,
} from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { ColumnsType } from "antd/es/table";

import { LPPUrls } from "../../../LPPUrls";
import { LPPAXIOS } from "../../../framework/api/core.api";

import { LoadingSpin } from "../../../components/common/Loading";
import { timeConverter } from "../../../utils/timeConverter";

import axios from "axios";

import Search from "antd/es/input/Search";
import { navFilter, urlFilter } from "../../../utils/paramsFilter";
import { MatchingApplyTable, MatchingColaboTable } from "./type";

const { Title } = Typography;
const { Header, Content } = Layout;

export const ApplyPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const status = params.get("status");

  const [currentPage, setCurrentPage] = useState(Number(params.get("page")));
  const [total, setTotal] = useState(0);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<MatchingApplyTable[]>([]);
  const [api, contextHolder] = notification.useNotification();
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const AdminColumn: ColumnsType<MatchingApplyTable> = [
    {
      title: "MD",
      dataIndex: "md",
    },
    {
      title: "셀러",
      dataIndex: "seller",
    },
    {
      title: "크리에이터",
      dataIndex: "creator",
    },
    {
      title: "승인상태",
      dataIndex: "status",
    },
    {
      title: "신청 일자",
      dataIndex: "createdAt",
      render: (_, record) => timeConverter(record.createdAt),
    },
  ];

  const handleTableChange = (value: any, filter: any, sorter: any) => {
    handleChangeSort(filter["status"]);
  };
  const handleChangeSort = (status: null | string) => {
    console.log("status: " + status);
    navigate(
      LPPUrls.Admin.Matching.Apply.url(
        currentPage,
        navFilter({ status: status })
      )
    );
    setCurrentPage(currentPage);
  };

  const handleOnChangePage: PaginationProps["onChange"] = (page) => {
    navigate(
      LPPUrls.Admin.Matching.Main.url(page, navFilter({ status: status }))
    );
    setCurrentPage(page);
  };
  const handleOnSearchFilter = (value: string) => {
    navigate(LPPUrls.Admin.Matching.Main.url(1, navFilter({ status: status })));
    setCurrentPage(1);
  };

  useEffect(() => {
    setLoading(true);
    LPPAXIOS.get(
      urlFilter({
        url: `/admin/match/collaboration/request/list`,
        page: currentPage,
        perPage: 25,
        status: status,
      })
    )
      .then((res) => {
        setData(res.data.data);
        setTotal(res.data.total);
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          api.error({
            message: "매칭 신청 목록을 불러오지 못했습니다.",
            description: error.response?.data.message,
          });
        }
      })
      .finally(() => setLoading(false));
  }, [currentPage, api, status]);

  return (
    <>
      <LoadingSpin loading={loading} />
      <Header
        style={{ background: colorBgContainer }}
        className="flex items-center justify-start py-1 my-2 mb-10 drop-shadow-sm"
      >
        <Title level={4}>매칭 콜라보 신청 조회</Title>
      </Header>
      <Content className="m-6 bg-white">
        <Table
          rowClassName={() => "cursor-pointer"}
          pagination={false}
          rowKey={(render) => render.id}
          dataSource={data}
          columns={AdminColumn}
          title={() => (
            <div className="flex items-center justify-between p-2">
              {/* <Search
                className="w-60"
                placeholder="매칭을 검색하세요."
                onSearch={handleOnSearchFilter}
              /> */}
            </div>
          )}
          footer={() => (
            <div className="flex justify-end">
              <Pagination
                current={currentPage}
                onChange={handleOnChangePage}
                pageSize={25}
                total={total}
                showSizeChanger={false}
              />
            </div>
          )}
          onRow={(record, _) => {
            return {
              onClick: () => {
                navigate(LPPUrls.Admin.Matching.ApplyDetail.url(record.id), {
                  state: {
                    apply: record,
                  },
                });
              }, // click row
            };
          }}
          onChange={(val: any, filter: any, sorter: any, extra: any) => {
            handleTableChange(val, filter, sorter);
          }}
        />
      </Content>
      {contextHolder}
    </>
  );
};

import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Layout,
  Breadcrumb,
  Typography,
  theme,
  Divider,
  Image,
  Space,
  Result,
  Button,
  Popconfirm,
  Card,
} from "antd";

import { LPPUrls } from "../../../LPPUrls";
import { useEffect, useLayoutEffect, useState } from "react";
import { LoadingSpin } from "../../../components/common/Loading";
import { LPPAXIOS } from "../../../framework/api/core.api";
import axios, { AxiosError } from "axios";

import { HContent } from "../../../components/common/HContent";
import useNotification from "antd/es/notification/useNotification";
// import { urlRegex } from "../../../utils/converter";
import { timeConverter } from "../../../utils/timeConverter";
import { RESPONSETYPE } from "./type";

const { Header } = Layout;
const { Title } = Typography;

export const DetailPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<RESPONSETYPE>();
  const [error, setError] = useState<AxiosError | Error | null>(null);

  const [api, contextHolder] = useNotification();

  const { id } = useParams();

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const handleDeleteBanner = () => {
    LPPAXIOS.delete(`/admin/banner/${id}`)
      .then(() => {
        api.success({
          message: "배너를 삭제하였습니다.",
        });
        setTimeout(() => {
          navigate(LPPUrls.Admin.Banner.Main.url());
        }, 500);
      })
      .catch(function (error) {
        if (error.response.status === 404) {
          api.error({
            message: "해당 배너를 찾을 수 없습니다.",
          });
        } else {
          api.error({
            message: "해당 배너를 찾을 수 없습니다.",
          });
        }
      });
  };

  useLayoutEffect(() => {
    setLoading(true);
    LPPAXIOS.get<RESPONSETYPE>(`/admin/banner/${id}`)
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          setError(error);
        }
      })
      .finally(() => setLoading(false));
  }, [id, api]);

  if (error !== null)
    return (
      <Result
        title={error?.message}
        subTitle="Sorry, the page you visited does not exist."
        extra={<Button type="primary">Back Home</Button>}
      />
    );
  return (
    <>
      <LoadingSpin loading={loading} />
      <Header
        style={{ background: colorBgContainer }}
        className="flex-col items-center justify-start py-1 my-1 mb-10 drop-shadow-sm"
      >
        <Title level={4}>배너</Title>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={LPPUrls.Admin.Banner.Main.url()}>배너</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>배너 상세 페이지</Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <HContent>
        <Typography>
          <Typography.Title level={4}>{data?.description}</Typography.Title>
          <Space className="flex justify-between">
            <Space>
              <Typography.Text>
                작성일자: {timeConverter(data?.createdAt ?? "0")}
              </Typography.Text>
            </Space>
            <Space size="small">
              <Button
                type="text"
                onClick={() =>
                  navigate(LPPUrls.Admin.Banner.Update.url(id!), {
                    state: data,
                  })
                }
              >
                수정
              </Button>
              |
              <Popconfirm
                title="배너 삭제"
                description="배너를 삭제하시겠습니까?"
                onConfirm={handleDeleteBanner}
                okText="예"
                cancelText="아니오"
              >
                <Button type="text">삭제</Button>
              </Popconfirm>
            </Space>
          </Space>

          <Divider orientation="left">배너 상세</Divider>
          <Image
            preview={false}
            width={300}
            src={`${process.env.REACT_APP_IMG_ENDPOINT}/${data?.image[0]}`}
          />
          <Divider orientation="left" children="배너 상품" />
          <div className="flex flex-wrap gap-2">
            {data?.productData.map((item, index) => {
              return (
                <Card
                  key={`product-${item.id}-container-${index}`}
                  className="w-[250px]"
                >
                  <Image
                    preview={false}
                    src={`${process.env.REACT_APP_IMG_ENDPOINT}/${item.image[0]}`}
                    width={100}
                    height={100}
                    style={{ objectFit: "contain" }}
                  />
                  <Title level={5}>{item.name}</Title>
                  <Divider />
                  <Typography>
                    <div className="flex flex-col">
                      <Typography.Text>
                        상품가격: <strong>{item.price}</strong>
                      </Typography.Text>
                      <Typography.Text>할인액: {item.discount}</Typography.Text>
                      <Typography.Text>
                        배송비: {item.deliveryFee}
                      </Typography.Text>
                    </div>
                  </Typography>
                </Card>
              );
            })}
          </div>
        </Typography>
      </HContent>
      {contextHolder}
    </>
  );
};

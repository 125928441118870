import { useEffect, useState } from "react";

import { LoadingSpin } from "../../../components/common/Loading";
import {
  Layout,
  Typography,
  theme,
  Input,
  Tag,
  Card,
  Button,
  Space,
  Divider,
} from "antd";

import { LPPAXIOS } from "../../../framework/api/core.api";
import useNotification from "antd/es/notification/useNotification";

const { Header, Content } = Layout;
const { Title } = Typography;

export interface Category {
  id: number;
  name: string;
  parentId: number;
}
export const CategoryPage = () => {
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState<Category[]>([]);
  const [currentLgCategory, setCurrentLgCategory] = useState<Category | null>(
    null
  );
  const [currentMdCategory, setCurrentMdCategory] = useState<Category | null>(
    null
  );
  const [currentSmCategory, setCurrentSmCategory] = useState<Category | null>(
    null
  );
  const [parentCategory, setParentSmCategory] = useState<Category | null>(null);

  const [inputValue, setInputValue] = useState("");

  const [api, contextHolder] = useNotification();

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const { token } = theme.useToken();
  const tagPlusStyle = {
    background: token.colorBgContainer,
    borderStyle: "dashed",
  };
  const tagFocusStyle = {
    background: token.colorFillSecondary,
    borderStyle: "solid",
  };

  const onClickLgCategory = (value: Category) => {
    if (currentLgCategory && currentLgCategory.name === value.name) {
      setCurrentLgCategory(null);
      setParentSmCategory(null);
    } else {
      setCurrentLgCategory(value);
      setParentSmCategory(value);
    }

    setCurrentMdCategory(null);
    setCurrentSmCategory(null);
  };
  const onClickMdCategory = (value: Category) => {
    setCurrentMdCategory(value);
    setCurrentSmCategory(null);
    setParentSmCategory(value);
  };
  const onClickSmCategory = (value: Category) => {
    setCurrentSmCategory(value);
  };

  const handleInputConfirm = async () => {
    LPPAXIOS.post<Category>("/admin/category", {
      parentId: parentCategory?.id ?? 0,
      name: inputValue,
    })
      .then((resolve) => {
        console.log(resolve.data);
        setCategory([...category, resolve.data]);
      })
      .catch((err) => {
        api.error({
          message: "카테고리를 추가하지 못했습니다.",
        });
      })
      .finally(() => {
        setInputValue("");
      });
  };

  useEffect(() => {
    setLoading(true);
    LPPAXIOS.get<Category[]>("/admin/category")
      .then((resolve) => {
        setCategory(resolve.data);
      })
      .catch(function (error) {
        if (error.response) {
          api.error({
            message: "카테고리를 가져올수 없습니다.",
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      {contextHolder}
      <LoadingSpin loading={loading} />
      <Header
        style={{ background: colorBgContainer }}
        className="flex items-center justify-start py-1 my-2 mb-10 drop-shadow-sm"
      >
        <Title level={4}>카테고리 설정</Title>
      </Header>

      <Content className="p-3 m-6 bg-white">
        <Title level={4}>카테고리 관리</Title>
        <div className="flex flex-col w-full gap-2">
          <Card
            title={
              <div>
                <div>현재 선택된 카테고리</div>
                {currentLgCategory?.name ? (
                  <span>
                    {currentLgCategory.name}
                    {">"}
                  </span>
                ) : (
                  <span>대분류{">"}</span>
                )}
                {currentMdCategory?.name && (
                  <span>
                    {currentMdCategory?.name}
                    {">"}
                  </span>
                )}

                {/* {currentSmCategory?.name && <span>소분류{">"}</span>} */}
                <span>추가될 위치</span>
              </div>
            }
          >
            <Space.Compact style={{ width: "100%" }}>
              <Input
                placeholder="추가하실 상위 카테고리를 선택 후 입력해주세요."
                value={inputValue}
                onChange={(e) => setInputValue(e.currentTarget.value.trim())}
              />
              <Button
                disabled={inputValue.trim().length === 0}
                type="primary"
                onClick={handleInputConfirm}
              >
                Submit
              </Button>
            </Space.Compact>
          </Card>
          <Card className="flex flex-wrap w-full min-h-[100px]">
            <div className="min-w-[100px]">대분류</div>
            <Divider />
            <div className="flex flex-wrap w-4/5 gap-2">
              {category.map((item, index) => {
                if (item.parentId === 0) {
                  return (
                    <Tag
                      onClick={() => onClickLgCategory(item)}
                      style={
                        currentLgCategory?.id === item.id
                          ? tagFocusStyle
                          : tagPlusStyle
                      }
                      key={`lg-ctg-${index}-${item.name}`}
                    >
                      {item.name}
                    </Tag>
                  );
                }
              })}
            </div>
          </Card>
          <Card
            className="flex flex-wrap w-full min-h-[100px]"
            // title={<div className="min-w-[100px]">중분류</div>}
          >
            <div className="min-w-[100px]">중분류</div>
            <Divider />
            {category.map((item, index) => {
              if (item.parentId === currentLgCategory?.id) {
                return (
                  <Tag
                    onClick={() => onClickMdCategory(item)}
                    style={
                      currentMdCategory?.id === item.id
                        ? tagFocusStyle
                        : tagPlusStyle
                    }
                    key={`md-ctg-${index}-${item.name}`}
                  >
                    {item.name}
                  </Tag>
                );
              }
            })}
          </Card>
          <Card
            className="flex flex-wrap w-full min-h-[100px]"
            // title={<div className="min-w-[100px]">소분류</div>}
          >
            <div className="min-w-[100px]">소분류</div>
            <Divider />
            {category.map((item, index) => {
              if (item.parentId === currentMdCategory?.id) {
                return (
                  <Tag
                    style={
                      currentSmCategory?.id === item.id
                        ? tagFocusStyle
                        : tagPlusStyle
                    }
                    key={`sm-ctg-${index}-${item.name}`}
                    onClick={() => onClickSmCategory(item)}
                  >
                    {item.name}
                  </Tag>
                );
              }
            })}
          </Card>
        </div>
      </Content>
    </>
  );
};

import { Navigate, Route, Routes } from "react-router-dom";
import { LPPUrls } from "../../../LPPUrls";
import { ApplyPage } from "./Apply";
import { ApplyDetailPage } from "./ApplyDetail";
import { DetailPage } from "./Detail";
import { TablePage } from "./Table";

export const IncomeRoot = () => {
  return (
    <Routes>
      <Route path="/" element={<TablePage />} />
      <Route
        path={LPPUrls.Admin.Income.Detail.pathName}
        element={<DetailPage />}
      />
      <Route
        path={LPPUrls.Admin.Income.Apply.pathName}
        element={<ApplyPage />}
      />
      <Route
        path={LPPUrls.Admin.Income.ApplyDetail.pathName}
        element={<ApplyDetailPage />}
      />
      <Route path="*" element={<Navigate to={"/"} replace />} />
    </Routes>
  );
};

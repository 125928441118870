import { Navigate, Route, Routes } from "react-router-dom";
import { LPPUrls } from "../../../LPPUrls";

import { DetailPage } from "./Detail";

export const CollaboOrderRoot = () => {
  return (
    <Routes>
      <Route
        path={LPPUrls.Admin.CollaboOrder.Detail.pathName}
        element={<DetailPage />}
      />

      <Route path="*" element={<Navigate to={"/"} replace />} />
    </Routes>
  );
};

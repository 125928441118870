import { useParams } from "react-router-dom";
import {
  Layout,
  Typography,
  Result,
  Image,
  Divider,
  Form,
  notification,
  Avatar,
  Carousel,
} from "antd";
import { LPPUrls } from "../../../LPPUrls";
import { useEffect, useState } from "react";
import axios, { AxiosError } from "axios";
import { LPPAXIOS } from "../../../framework/api/core.api";
import { LoadingSpin } from "../../../components/common/Loading";
import { HContent } from "../../../components/common/HContent";

import { timeConverter } from "../../../utils/timeConverter";

export interface ReviewDetail {
  id: number;
  userId: number;
  reviewUserId: number;
  creatorId: number;
  content: string[];
  photos: string[];
  video: string;
  thumbnail: string;
  description: string;
  isShow: number;
  isRecommend: number;
  view: number;
  totalLike: number;
  totalComment: number;
  createdAt: string;
  updatedAt: string;
  profile: string;
  nickName: string;
  productData: ProductDaum[];
}

export interface ProductDaum {
  id: number;
  productName: string;
  productRepresentativeImage: string;
  originPrice: number;
  isDiscounted: number;
  discountedPrice: number;
}
export const DetailPage = () => {
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [data, setData] = useState<ReviewDetail>();
  const [error, setError] = useState<any | AxiosError | null>(null);
  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    setLoading(true);
    LPPAXIOS.get<ReviewDetail>(`/admin/post/${id!}`)
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          setError(error);
        }
      })
      .finally(() => setLoading(false));
  }, [id]);

  if (error !== null) return <Result status={404} title={"404"} />;
  if (loading && data === undefined) return <LoadingSpin loading={loading} />;

  return (
    <>
      {contextHolder}
      <LoadingSpin loading={loading} />

      <HContent>
        {/* 리뷰 보여줄 곳 */}
        <Typography>
          <Avatar
            src={`${process.env.REACT_APP_IMG_ENDPOINT}/${data?.profile}`}
            size="small"
          />
          <Typography.Text>{data?.nickName}</Typography.Text>
        </Typography>
        <Typography>
          <Typography.Text>
            작성일: {timeConverter(data?.createdAt ?? "")}
          </Typography.Text>
        </Typography>
        <Typography>
          <Typography.Text>조회수: {data?.view}</Typography.Text>
        </Typography>
        <Divider
          orientation="center"
          orientationMargin={3}
          children="리뷰상품"
        />
        {data?.productData && (
          <Carousel>
            {data.productData.map((product, index) => (
              <div className="w-[400px] p-2  bg-slate-500 rounded-lg flex justify-start items-start gap-1">
                <Image
                  preview={false}
                  width={120}
                  height={120}
                  style={{ objectFit: "cover", borderRadius: 4 }}
                  src={`${process.env.REACT_APP_IMG_ENDPOINT}/${product.productRepresentativeImage}`}
                />

                <div>
                  <p className="w-[270px] font-semibold text-white truncate">
                    {product.productName}
                  </p>
                  <span className="text-white line-through ">
                    {product.originPrice}원
                  </span>
                  <p className="text-red-300">
                    {product.originPrice - product.discountedPrice}원
                  </p>
                  {product.isDiscounted === 1 && (
                    <span className="text-lime-300">
                      {(product.discountedPrice / product.originPrice) * 100}%
                      할인
                    </span>
                  )}
                </div>
              </div>
            ))}
          </Carousel>
        )}

        {/* 리뷰 댓글 보여줄 곳 */}
        <Divider orientation="center" orientationMargin={3} children="리뷰" />
        <video
          poster={`${process.env.REACT_APP_IMG_ENDPOINT}/${data?.thumbnail}`}
          src={`${process.env.REACT_APP_IMG_ENDPOINT}/${data?.video}`}
          width="300"
          autoPlay
          controls
          controlsList="nodownload"
        />
      </HContent>
    </>
  );
};

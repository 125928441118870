import {
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import {
  Layout,
  Breadcrumb,
  Typography,
  theme,
  notification,
  Descriptions,
  Space,
  Button,
  Image,
  Result,
  Row,
  Col,
  Card,
  Alert,
  Skeleton,
  Avatar,
  Tag,
} from "antd";

import { LPPUrls } from "../../../LPPUrls";
import { useEffect, useState } from "react";
import { LPPAXIOS } from "../../../framework/api/core.api";
import { timeConverter } from "../../../utils/timeConverter";
import { MatchingApplyTable } from "./type";
import { DetailSELLER } from "../Seller/type";
import { DetailCREATOR } from "../Creator/type";
import { DetailMD } from "../MD/type";
import Meta from "antd/es/card/Meta";

const { Header, Content } = Layout;
const { Title } = Typography;

export const ApplyDetailPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  const apply = state?.apply as MatchingApplyTable;

  const [seller, setSeller] = useState<DetailSELLER | null>(null);
  const [creator, setCreator] = useState<DetailCREATOR | null>(null);
  const [md, setMD] = useState<DetailMD | null>(null);
  const [loading, setLoading] = useState(true);

  const [api, contextHolder] = notification.useNotification();
  const localData = localStorage.getItem("lppuser");
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const onClickBack = () => {
    navigate(LPPUrls.Admin.Matching.Apply.url());
  };
  const handleReject = async () => {
    setLoading(true);
    await LPPAXIOS.put(`/admin/match/collaboration/request/${id}`, {
      id: id,
      status: "reject",
    })
      .then(() => {
        api.success({
          message: "매칭 승인 거부",
        });
        setTimeout(() => {
          navigate(LPPUrls.Admin.Matching.Apply.url(), { replace: true });
        }, 600);
      })
      .catch(function (error) {
        if (error.response.status === 404) {
          api.error({
            message: "해당 유저를 찾을 수 없습니다.",
            description: `${error.response.status} Error`,
          });
        } else if (error.response.status === 409) {
          api.error({
            message: " 계정은 삭제할 수 없습니다.",
            description: `${error.response.status} Error`,
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleApprove = async () => {
    setLoading(true);
    await LPPAXIOS.put(`/admin/match/collaboration/request/${id}`, {
      id: id,
      status: "accept",
    })
      .then(() => {
        api.success({
          message: "매칭 승인 성공",
        });
        setTimeout(() => {
          navigate(LPPUrls.Admin.Matching.Apply.url(), { replace: true });
        }, 600);
      })
      .catch(function (error) {
        if (error.response.status === 404) {
          api.error({
            message: "해당 유저를 찾을 수 없습니다.",
            description: `${error.response.status} Error`,
          });
        } else if (error.response.status === 409) {
          api.error({
            message: " 계정은 삭제할 수 없습니다.",
            description: `${error.response.status} Error`,
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!apply) return;
      setLoading(true);

      try {
        const fetchSeller = LPPAXIOS.get(`/admin/seller/list/${apply.sellerId}`)
          .then((res) => setSeller(res.data))
          .catch((err) => console.log("Seller Error:", err));
        const fetchCreator = LPPAXIOS.get(
          `/admin/creator/list/${apply.creatorId}`
        )
          .then((res) => setCreator(res.data))
          .catch((err) => console.log("Creator Error:", err));
        const fetchMD = LPPAXIOS.get(`/admin/md/${apply.mdId}`)
          .then((res) => setMD(res.data))
          .catch((err) => console.log("MD Error:", err));

        await Promise.all([fetchSeller, fetchCreator, fetchMD]);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [apply]);

  if (localData === null) {
    return <Navigate to={LPPUrls.Auth.Root.pathName} replace />;
  }

  const isAdmin = JSON.parse(localData).role === "admin" ? true : false;
  if (!isAdmin) {
    return <Navigate to={LPPUrls.Auth.Root.pathName} replace />;
  }
  if (apply === undefined || apply === null) {
    return (
      <Result
        status={404}
        title={404}
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Button type="primary" onClick={onClickBack}>
            Back Home
          </Button>
        }
      />
    );
  }

  return (
    <>
      {contextHolder}

      <Header
        style={{ background: colorBgContainer }}
        className="flex-col items-center justify-start py-1 my-1 mb-10 drop-shadow-sm"
      >
        <Title level={4}>매칭 콜라보 신청 상세 조회</Title>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={LPPUrls.Admin.Matching.Apply.url()}>
              매칭 콜라보 신청 조회
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>매칭 콜라보 신청 상세 조회</Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <Content className="p-2 m-6 bg-white">
        {loading ? (
          <Skeleton active={loading} />
        ) : (
          <Row gutter={[8, 8]}>
            {/* MD */}
            <Col span={24}>
              <Card title="MD">
                {md ? (
                  <Meta
                    //  avatar={
                    //    <Avatar
                    //      size={64}
                    //      src={`${process.env.REACT_APP_IMG_ENDPOINT}/${md.}`}
                    //    />
                    //  }
                    title={md.nickName}
                    description={
                      <p>
                        연락처: {md.phone}
                        <br />
                        이메일: {md.email}
                      </p>
                    }
                  />
                ) : (
                  <Alert
                    type="warning"
                    message="계정 정보를 조회하지 못했습니다."
                  />
                )}
              </Card>
            </Col>
            {/* Seller */}
            <Col sm={24} md={12}>
              <Card title="SELLER">
                {seller ? (
                  <Meta
                    avatar={
                      <Avatar
                        size={64}
                        src={`${process.env.REACT_APP_IMG_ENDPOINT}/${seller.profile}`}
                      />
                    }
                    title={<Space>{seller.nickName}</Space>}
                    description={
                      <p>
                        연락처: {seller.phone}
                        <br />
                        이메일: {seller.email}
                        <br />
                        브랜드관: {seller.brandName}
                      </p>
                    }
                  />
                ) : (
                  <Alert
                    type="warning"
                    message="계정 정보를 조회하지 못했습니다."
                  />
                )}
              </Card>
            </Col>
            {/* Creator */}
            <Col sm={24} md={12}>
              <Card title="CREATOR">
                {creator ? (
                  <Meta
                    avatar={
                      <Avatar
                        size={64}
                        src={`${process.env.REACT_APP_IMG_ENDPOINT}/${creator.profile}`}
                      />
                    }
                    title={
                      <Space>
                        {creator.nickName}
                        <StatusTag status={creator.status} />
                      </Space>
                    }
                    description={
                      <p>
                        YouTube: {creator.social_youtube}
                        <br />
                        FaceBook: {creator.social_facebook}
                        <br />
                        Instagram: {creator.social_instagram}
                      </p>
                    }
                  />
                ) : (
                  <Alert
                    type="warning"
                    message="계정 정보를 조회하지 못했습니다."
                  />
                )}
              </Card>
            </Col>
          </Row>
        )}

        <Space className="flex justify-end w-full p-2">
          <Button onClick={handleReject} type="default">
            거절
          </Button>
          <Button
            onClick={handleApprove}
            type="primary"
            disabled={!md || !creator || !seller}
          >
            승인
          </Button>
        </Space>
      </Content>
    </>
  );
};

interface IStatusTag {
  status: string;
}
function StatusTag(props: IStatusTag) {
  const { status } = props;

  switch (status) {
    case "general":
      return <Tag color="green">정상계정</Tag>;
    case "dormancy":
      return <Tag color="gold">휴면계정</Tag>;
    case "delete":
      return <Tag color="pink">탈퇴계정</Tag>;
    default:
      return <Tag>이상계정</Tag>;
  }
}

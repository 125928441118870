const topLevelAdmin = (name: string) => {
  return {
    Main: {
      url: (page: number = 1, keyword: string = "") =>
        `/admin/${name}?page=${page}${keyword}`,
      pathName: `/${name}/*`,
    },
    key: name,

    ReportList: {
      url: (page: number = 1, keyword: string = "") =>
        `/admin/${name}/ReportList/?page=${page}${keyword}`,
      pathName: `ReportList/*`,
      key: `${name}ReportList`,
    },

    Detail: {
      url: (id: string | number) => `/admin/${name}/detail/${id}`,
      pathName: "detail/:id",
      key: `${name}detail`,
    },
    Update: {
      url: (id: string | number) => `/admin/${name}/update/${id}`,
      pathName: "update/:id",
      key: `${name}update`,
    },
    Create: {
      url: `/admin/${name}/create/`,
      pathName: `create/`,
      key: `${name}create`,
    },
    BoardInfo: {
      url: (id: string | number) => `/admin/${name}/boardInfo/${id}`,
      pathName: "boardInfo/:id",
      key: `${name}boardInfo`,
    },
    UploadBanner: {
      url: (id: string | number) => `/admin/${name}/uploadBanner/${id}`,
      pathName: "uploadBanner/:id",
      key: `${name}uploadBanner`,
    },
    Apply: {
      url: (page: number = 1, keyword: string = "") =>
        `/admin/${name}/apply?page=${page}${keyword}`,
      pathName: "apply/",
      key: `${name}apply`,
    },
    ApplyDetail: {
      url: (id: string | number) => `/admin/${name}/applyDetail/${id}`,
      pathName: "applyDetail/:id",
      key: `${name}applyDetail`,
    },
  };
};

export const LPPUrls = {
  Auth: {
    Root: {
      url: "/auth",
      pathName: "/auth/*",
    },
    Login: {
      Main: {
        url: "/auth/adminLogin",
        pathName: "adminLogin",
      },
    },
    FindPassword: {
      url: "/auth/findPassword",
      pathName: "findPassword",
    },
  },
  Admin: {
    // url:'/admin',
    // Landing: '/admin/adminAccount/',
    Root: {
      url: "/admin",
      pathName: "/admin/*",
    },
    MyPage: topLevelAdmin("MyPage"),
    adminAccount: topLevelAdmin("adminAccount"),
    Notation: topLevelAdmin("Notation"),
    Users: topLevelAdmin("User"),

    QnA: topLevelAdmin("QnA"),
    Orders: topLevelAdmin("Orders"),
    Products: {
      ...topLevelAdmin("Products"),
      Category: {
        url: `/admin/Products/category/`,
        pathName: "category/",
        key: `Productscategory`,
      },
    },
    Banner: topLevelAdmin("Banner"),
    Creator: topLevelAdmin("Creator"),
    Seller: topLevelAdmin("Seller"),
    Review: topLevelAdmin("Review"),
    Income: topLevelAdmin("Income"),
    Matching: topLevelAdmin("Matching"),
    CollaboOrder: topLevelAdmin("CollaboOrder"),
    MatchingRoom: topLevelAdmin("MatchingRoom"),
    MD: topLevelAdmin("MD"),
    Report: topLevelAdmin("Report"),
    BlockUser: topLevelAdmin("BlockUsereport"),
    Settlement: topLevelAdmin("Settlement"),
  },
};

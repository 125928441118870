import {
  Layout,
  Typography,
  theme,
  Table,
  Button,
  Pagination,
  PaginationProps,
  notification,
} from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import type { ColumnsType } from "antd/es/table";

import { LPPUrls } from "../../../LPPUrls";
import { LPPAXIOS } from "../../../framework/api/core.api";
import { AdminTag } from "../../../components/common/AdminTag";
import { StaffTag } from "../../../components/common/StaffTag";
import { LoadingSpin } from "../../../components/common/Loading";
import { timeConverter } from "../../../utils/timeConverter";

import axios from "axios";
import { Administrators, LevelType } from "./type";
import Search from "antd/es/input/Search";
import { navFilter, urlFilter } from "../../../utils/paramsFilter";

const { Title } = Typography;
const { Header, Content } = Layout;

export interface REPONSETYPE {
  data: Administrators[];
  total: number;
}

export const TablePage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const search = params.get("search");
  const order = params.get("order");
  const sort = params.get("sort");

  const [currentPage, setCurrentPage] = useState(Number(params.get("page")));
  const [total, setTotal] = useState(0);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Administrators[]>([]);
  const [api, contextHolder] = notification.useNotification();
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const AdminColumn: ColumnsType<Administrators> = [
    {
      title: "이름",
      dataIndex: "nickName",
    },
    {
      title: "생성일",
      dataIndex: "createdAt",
      sorter: true,
      defaultSortOrder:
        sort === "createdAt" ? (order === "ASC" ? "ascend" : "descend") : null,
      render: (_, record) => timeConverter(record.createdAt),
    },
    {
      title: "권한",
      dataIndex: "LevelType",
      render: (_, record) => {
        if (record.role === LevelType.ADMIN) return <AdminTag />;
        if (record.role === LevelType.STAFF) return <StaffTag />;
        return "-";
      },
    },
  ];
  const handleTableChange = (value: any, filter: any, sorter: any) => {
    console.log(sorter);
    let order =
      sorter.order === undefined
        ? null
        : sorter.order === "ascend"
        ? "ASC"
        : "DESC";
    let sort = sorter.field ?? null;

    handleChangeSort(sort, order);
  };
  const handleChangeSort = (sort: any = null, order: any = null) => {
    navigate(
      LPPUrls.Admin.adminAccount.Main.url(
        currentPage,
        navFilter({ search: search, sort: sort, order: order })
      )
    );
    setCurrentPage(currentPage);
  };

  const showComfirm = () => {
    navigate(LPPUrls.Admin.adminAccount.Create.url);
  };

  const handleOnChangePage: PaginationProps["onChange"] = (page) => {
    navigate(
      LPPUrls.Admin.adminAccount.Main.url(
        page,
        navFilter({ search: search, sort: sort, order: order })
      )
    );
    setCurrentPage(page);
  };
  const handleOnSearchFilter = (value: string) => {
    navigate(
      LPPUrls.Admin.adminAccount.Main.url(
        1,
        navFilter({ search: value, sort: sort, order: order })
      )
    );
    setCurrentPage(1);
  };

  useEffect(() => {
    setLoading(true);
    LPPAXIOS.get<REPONSETYPE>(
      urlFilter({
        url: `/admin/administrators`,
        page: currentPage,
        perPage: 25,
        search: search,
        sort: sort,
        order: order,
      })
    )
      .then((res) => {
        setData(res.data.data);
        setTotal(res.data.total);
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          api.error({
            message: "관리자 목록을 불러오지 못했습니다.",
            description: error.response?.data.message,
          });
        }
      })
      .finally(() => setLoading(false));
  }, [currentPage, api, search, sort, order]);

  return (
    <>
      <LoadingSpin loading={loading} />
      <Header
        style={{ background: colorBgContainer }}
        className="flex items-center justify-start py-1 my-2 mb-10 drop-shadow-sm"
      >
        <Title level={4}>Admin Account</Title>
      </Header>
      <Content className="m-6 bg-white">
        <Table
          rowClassName={() => "cursor-pointer"}
          pagination={false}
          rowKey={(render) => render.id}
          dataSource={data}
          columns={AdminColumn}
          title={() => (
            <div className="flex items-center justify-between p-2">
              <Search
                className="w-60"
                placeholder="관리자를 검색하세요."
                onSearch={handleOnSearchFilter}
              />
              <Button type="primary" onClick={showComfirm}>
                관리자 추가
              </Button>
            </div>
          )}
          footer={() => (
            <div className="flex justify-end">
              <Pagination
                current={currentPage}
                onChange={handleOnChangePage}
                pageSize={25}
                total={total}
                showSizeChanger={false}
              />
            </div>
          )}
          onRow={(record, _) => {
            return {
              onClick: () => {
                navigate(LPPUrls.Admin.adminAccount.Detail.url(record.id));
              }, // click row
            };
          }}
          onChange={(val: any, filter: any, sorter: any, extra: any) => {
            handleTableChange(val, filter, sorter);
          }}
        />
      </Content>
      {contextHolder}
    </>
  );
};

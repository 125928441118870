import {
  Layout,
  Typography,
  theme,
  Table,
  Pagination,
  PaginationProps,
  notification,
  Tag,
} from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { ColumnsType } from "antd/es/table";

import { LPPUrls } from "../../../LPPUrls";
import { LPPAXIOS } from "../../../framework/api/core.api";

import { LoadingSpin } from "../../../components/common/Loading";
import { timeConverter } from "../../../utils/timeConverter";

import axios from "axios";

import Search from "antd/es/input/Search";
import { INCOMEAPPLYRESPONSE, INCOMEAPPPLY } from "./type";
import { navFilter, urlFilter } from "../../../utils/paramsFilter";

export interface Root {
  data: Daum[];
  total: number;
}

export interface Daum {
  id: number;
  nickname: string;
  profile: string;
  phone: string;
  createdAt: string;
}

const { Title } = Typography;
const { Header, Content } = Layout;

export const ApplyPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  // const status = params.get("status");
  const type = params.get("type");

  const [currentPage, setCurrentPage] = useState(Number(params.get("page")));
  const [total, setTotal] = useState(0);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<INCOMEAPPPLY[]>([]);
  const [api, contextHolder] = notification.useNotification();
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const AdminColumn: ColumnsType<INCOMEAPPPLY> = [
    {
      title: "유저 닉네임",
      dataIndex: "nickName",
      key: "nickName",
      width: "15%",
      fixed: true,
    },
    {
      title: "유저타입",
      dataIndex: ["role"],
      key: "role",
      render: (role: string) => {
        return <Tag>{role}</Tag>;
      },
    },
    {
      title: "정산 요청 금액",
      dataIndex: "paidAmount",
      key: "paidAmount",
    },
    {
      title: "연락처",
      dataIndex: "phone",
      key: "phone",
    },

    {
      title: "정산 상태",
      dataIndex: ["isPaid"],
      key: "isPaid",
      render: (isPaid: number) => {
        if (isPaid === 1) {
          return <Tag color="green">지급 완료</Tag>;
        } else {
          return <Tag color="gold">지급 대기중</Tag>;
        }
      },
    },
    {
      title: "정산 요청일",
      dataIndex: ["createdAt"],
      key: "createdAt",
    },
  ];

  const handleTableChange = (value: any, filter: any, sorter: any) => {
    console.log(filter);
    handleChangeSort(filter["userType"]);
  };
  const handleChangeSort = (type: null | string) => {
    console.log("status: " + type);
    navigate(
      LPPUrls.Admin.Income.Apply.url(currentPage, navFilter({ type: type }))
    );
    setCurrentPage(currentPage);
  };

  const handleOnChangePage: PaginationProps["onChange"] = (page) => {
    navigate(LPPUrls.Admin.Income.Apply.url(page, navFilter({ type: type })));
    setCurrentPage(page);
  };
  const handleOnSearchFilter = (value: string) => {
    navigate(LPPUrls.Admin.Income.Apply.url(1, navFilter({ type: type })));
    setCurrentPage(1);
  };

  useEffect(() => {
    setLoading(true);
    LPPAXIOS.get<INCOMEAPPLYRESPONSE>(
      urlFilter({
        url: `/admin/settlement/request`,
        page: currentPage,
        perPage: 25,
        type: type,
      })
    )
      .then((res) => {
        setData(res.data.data);
        setTotal(res.data.total);
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          api.error({
            message: "크리에이터 목록을 불러오지 못했습니다.",
            description: error.response?.data.message,
          });
        }
      })
      .finally(() => setLoading(false));
  }, [currentPage, api, type]);

  return (
    <>
      <LoadingSpin loading={loading} />
      <Header
        style={{ background: colorBgContainer }}
        className="flex items-center justify-start py-1 my-2 mb-10 drop-shadow-sm"
      >
        <Title level={4}>정산 신청 관리</Title>
      </Header>
      <Content className="m-6 bg-white">
        <Table
          rowClassName={() => "cursor-pointer"}
          pagination={false}
          rowKey={(render) => render.id}
          dataSource={data}
          columns={AdminColumn}
          title={() => (
            <div className="flex items-center justify-between p-2">
              <Title level={4}>정산 신청 리스트</Title>
              {/* <Search
                className="w-60"
                placeholder="크리에이터를 검색하세요."
                onSearch={handleOnSearchFilter}
              /> */}
            </div>
          )}
          footer={() => (
            <div className="flex justify-end">
              <Pagination
                current={currentPage}
                onChange={handleOnChangePage}
                pageSize={25}
                total={total}
                showSizeChanger={false}
              />
            </div>
          )}
          onRow={(record, _) => {
            return {
              onClick: () => {
                navigate(LPPUrls.Admin.Income.ApplyDetail.url(record.id));
              }, // click row
            };
          }}
          onChange={(val: any, filter: any, sorter: any, extra: any) => {
            handleTableChange(val, filter, sorter);
          }}
        />
      </Content>
      {contextHolder}
    </>
  );
};
